import React, { useState, useRef, useEffect } from 'react'

import { useServices, useObservable } from '../../ui/hooks'
import checkMark from '../../assets/icons/checkmark-ico_green.svg'
import { Modal } from '../common'

export function SignInModal () {
  const { accountService } = useServices()
  const [address] = useObservable(() => accountService.getAddressObservable(), [])
  const [visible, setVisible] = useState(false)
  const lastAddress = useRef(address)

  useEffect(() => {
    if (address && lastAddress.current === null) {
      setVisible(true)
    }
    lastAddress.current = address
  }, [address])

  function close () {
    setVisible(false)
  }

  if (!visible) {
    return null
  }

  return (
    <Modal small className="sign-in-modal" onClose={close}>
      <img className="sign-in-modal__check-mark" src={checkMark} alt="Success" />
      <p className="sign-in-modal__text">Successfully logged in!</p>
      <button className="button" onClick={close}>Close</button>
    </Modal>
  )
}
