import React from 'react'
import { Link } from 'react-router-dom'

import createIcon from '../../../assets/icons/create.png'
import { useServices, useSignIn } from '../../../ui/hooks'

import { NavBarTemplate } from './NavBarTemplate'
import { NavBarTab } from './NavBarTab'

export interface NavBarProps {
  noLinks?: boolean,
  teosto?: boolean,
  className?: 'white' | 'beige',
}

export function NavBar ({ noLinks, teosto, className }: NavBarProps) {
  const { accountService } = useServices()
  const signIn = useSignIn()
  const signedIn = accountService.isSignedIn()

  return (
    <NavBarTemplate className={className}>
      {!noLinks && signedIn && (
        <div className="navbar__tabs">
          <NavBarTab to="/assets" disabled={teosto}>Assets</NavBarTab>
          <NavBarTab to={`${teosto ? '/teosto' : ''}/agreements`}>Agreements</NavBarTab>
          <NavBarTab to="/activity" disabled>Activity</NavBarTab>
        </div>
      )}
      {signedIn && !teosto && !noLinks && (
        <div className="navbar__actions">
          <Link
            className="navbar__action-button"
            to="/create-agreement"
          >
            <img src={createIcon} alt="new agreement" />
          </Link>
        </div>
      )}
      {!signedIn && (
        <div className="navbar__sign-in-field">
          <button
            className="navbar__sign-in-button"
            onClick={signIn}
          >
            Sign in
          </button>
        </div>
      )}
    </NavBarTemplate>
  )
}
