"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ETHAddress = void 0;
const ethers_1 = require("ethers");
class ETHAddress {
    constructor(value) {
        this.normalized = ethers_1.utils.getAddress(value);
    }
    get lowerCased() {
        return this.normalized.toLowerCase();
    }
    static random() {
        return new ETHAddress(ethers_1.Wallet.createRandom().address);
    }
    equals(other) {
        if (typeof other === 'string') {
            try {
                return this.normalized === ethers_1.utils.getAddress(other);
            }
            catch (_a) {
                return false;
            }
        }
        else {
            return this.normalized === other.normalized;
        }
    }
    toJSON() {
        return this.normalized;
    }
    toString() {
        return this.normalized;
    }
}
exports.ETHAddress = ETHAddress;
