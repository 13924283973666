import React from 'react'
import cx from 'classnames'

import { Period } from '@original-works/original-works-core/utils'

export interface PeriodSelectorProps {
  value: Period,
  onChange: (value: Period) => void,
}

export function PeriodSelector ({ value, onChange }: PeriodSelectorProps) {
  return (
    <div className="eh-period-selector">
      <button
        className={cx('eh-period-selector__button', value === 'daily' && 'eh-period-selector__button--active')}
        onClick={() => onChange('daily')}
      >Daily</button>
      <button
        className={cx('eh-period-selector__button', value === 'monthly' && 'eh-period-selector__button--active')}
        onClick={() => onChange('monthly')}
      >Monthly</button>
    </div>
  )
}
