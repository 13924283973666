import React, { useState } from 'react'

import { Holder } from '@original-works/original-works-core/models'

import { isEqual, sum, isGreaterThan } from '../../services/tokens'
import { TabBar } from './views/TabBar'
import { TitleStep, DescriptionStep, HoldersStep } from './steps'
import { Values } from './values'

interface AgreementWizardProps {
  userAddress: string,
  onClose (): void,
  onSubmit (values: Values): void,
}

export function AgreementWizard (props: AgreementWizardProps) {
  const [values, setValues] = useState(getInitialValues(props.userAddress))
  const [errors, setErrors] = useState(INITIAL_ERRORS)
  const [step, setStep] = useState(0)

  function setValue <T extends keyof Values> (key: T, value: Values[T]) {
    const newValues = {
      ...values,
      [key]: value,
    }
    setValues(newValues)
    setErrors(validate(newValues))
  }

  function onTitleChange (value: string) {
    setValue('title', value)
  }

  function onDescriptionChange (value: string) {
    setValue('description', value)
  }

  function onHoldersChange (value: Holder[]) {
    if (value.length !== 0) {
      setValue('holders', value)
    }
  }

  function onStepClick (index: number) {
    if (index < step) {
      setStep(index)
    }
  }

  function onSubmit () {
    switch (step) {
      case 0: if (!errors.title) setStep(1); break
      case 1: setStep(2); break
      case 2: if (!errors.holders) props.onSubmit(values); break
    }
  }

  return (
    <>
      <TabBar
        title="Create Agreement"
        labels={['01 Title', '02 Description', '03 Rights Holders']}
        currentStep={step}
        onStepClick={onStepClick}
        onClose={props.onClose}
      />
      {step === 0 && (
        <TitleStep
          {...values}
          onChange={onTitleChange}
          onSubmit={onSubmit}
        />
      )}
      {step === 1 && (
        <DescriptionStep
          {...values}
          onChange={onDescriptionChange}
          onSubmit={onSubmit}
          goBack={() => setStep(0)}
        />
      )}
      {step === 2 && (
        <HoldersStep
          {...values}
          error={errors.holders}
          onChange={onHoldersChange}
          onSubmit={onSubmit}
          goBack={() => setStep(1)}
        />
      )}
    </>
  )
}

function getInitialValues (userAddress: string): Values {
  return {
    title: '',
    description: '',
    totalSupply: '1000000',
    holders: [{
      address: userAddress,
      balance: '1000000',
      isAdmin: true,
    }],
  }
}

const INITIAL_ERRORS = {
  title: true,
  holders: true,
}

function validate (values: Values) {
  const holderBalances = sum(values.holders.map(x => x.balance))

  return {
    title: values.title === '',
    description: values.description === '',
    totalSupply: values.totalSupply === '',
    holders: !isEqual(holderBalances, values.totalSupply) ||
      values.holders.some(x => !isGreaterThan(x.balance, 0)),
  }
}

export default AgreementWizard
