import dayjs from 'dayjs'
import React from 'react'
import { Link } from 'react-router-dom'

import { ApiAgreementContract } from '@original-works/original-works-core/models'

import agreementIcon from '../../../assets/icons/agreement.svg'
import options from '../../../assets/icons/more.svg'
import { ContractIcon } from '../../../ui/common'
import { OptionsMenu } from '../../../ui/Contracts/OptionsMenu'

import { getUserShares } from './utils'
import { OwnerInfo } from './OwnerInfo'

export interface AgreementListItemProps {
  agreement: ApiAgreementContract,
  onlyShared: boolean,
  user: string,
}

export function AgreementListItem ({ agreement, onlyShared, user }: AgreementListItemProps) {
  const createdAt = dayjs.unix(agreement.timestamp).format('DD MMM YYYY')
  const shares = getUserShares(agreement.holders, user, agreement.totalSupply)

  return (
    <Link
      className="contracts-list__item agreements-list__item"
      to={`/agreement/${agreement.address}`}
    >
      <div className="contracts-list__item__main-data">
        <ContractIcon src={agreementIcon} className="contracts-list__item-icon" />
        <p className="contracts-list__item-title">{agreement.title}</p>
      </div>
      {!onlyShared && <ShareHoldersInfo holders={agreement.holders.length} />}
      {onlyShared && <OwnerInfo address={agreement.holders[0].address} />}
      <p className="contracts-list__item-date">{createdAt}</p>
      <p className="contracts-list__item-shares">{shares}</p>
      <div className="contracts-list__item-options">
        <img src={options} alt="options"/>
        <OptionsMenu contractAddress={agreement.address} />
      </div>
    </Link>
  )
}

interface ShareHoldersInfoProps {
  holders: number,
}

function ShareHoldersInfo ({ holders }: ShareHoldersInfoProps) {
  return (
    <p className="contracts-list__item-holders">
      {holders === 1 && 'Only you'}
      {holders === 2 && 'With you & 1 other'}
      {holders > 2 && `With you & ${holders - 1} others`}
    </p>
  )
}
