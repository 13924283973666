"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractTitle = exports.getTotalBalance = exports.toFormattedDuration = exports.getUserBalance = exports.filterByTitle = exports.pickMaxChartValue = exports.sumAllAfter = exports.toEarningsSummaryData = exports.toChartData = exports.toEarningsChartData = exports.getPayoutTransfers = exports.CachingSubject = exports.formatFixedPercentage = exports.formatPercentage = exports.calculatePercentage = exports.calculateValueFromPercentage = exports.percentageToBalance = exports.toFormattedFixedPercentage = exports.toFormattedExactPercentage = exports.toFormattedPercentage = exports.formatCurrency = exports.formatCryptoValue = exports.formatFiatValue = exports.formatNumberToPrecision = exports.formatNumber = exports.applyCommaSeparator = void 0;
__exportStar(require("./add"), exports);
__exportStar(require("./sleep"), exports);
__exportStar(require("./zeroAddress"), exports);
var numberFormatters_1 = require("./numberFormatters");
Object.defineProperty(exports, "applyCommaSeparator", { enumerable: true, get: function () { return numberFormatters_1.applyCommaSeparator; } });
Object.defineProperty(exports, "formatNumber", { enumerable: true, get: function () { return numberFormatters_1.formatNumber; } });
Object.defineProperty(exports, "formatNumberToPrecision", { enumerable: true, get: function () { return numberFormatters_1.formatNumberToPrecision; } });
Object.defineProperty(exports, "formatFiatValue", { enumerable: true, get: function () { return numberFormatters_1.formatFiatValue; } });
Object.defineProperty(exports, "formatCryptoValue", { enumerable: true, get: function () { return numberFormatters_1.formatCryptoValue; } });
Object.defineProperty(exports, "formatCurrency", { enumerable: true, get: function () { return numberFormatters_1.formatCurrency; } });
var percentage_1 = require("./percentage");
Object.defineProperty(exports, "toFormattedPercentage", { enumerable: true, get: function () { return percentage_1.toFormattedPercentage; } });
Object.defineProperty(exports, "toFormattedExactPercentage", { enumerable: true, get: function () { return percentage_1.toFormattedExactPercentage; } });
Object.defineProperty(exports, "toFormattedFixedPercentage", { enumerable: true, get: function () { return percentage_1.toFormattedFixedPercentage; } });
Object.defineProperty(exports, "percentageToBalance", { enumerable: true, get: function () { return percentage_1.percentageToBalance; } });
Object.defineProperty(exports, "calculateValueFromPercentage", { enumerable: true, get: function () { return percentage_1.calculateValueFromPercentage; } });
Object.defineProperty(exports, "calculatePercentage", { enumerable: true, get: function () { return percentage_1.calculatePercentage; } });
Object.defineProperty(exports, "formatPercentage", { enumerable: true, get: function () { return percentage_1.formatPercentage; } });
Object.defineProperty(exports, "formatFixedPercentage", { enumerable: true, get: function () { return percentage_1.formatFixedPercentage; } });
var CachingSubject_1 = require("./CachingSubject");
Object.defineProperty(exports, "CachingSubject", { enumerable: true, get: function () { return CachingSubject_1.CachingSubject; } });
__exportStar(require("./reactive"), exports);
__exportStar(require("./CurrencyValue"), exports);
var payouts_1 = require("./payouts");
Object.defineProperty(exports, "getPayoutTransfers", { enumerable: true, get: function () { return payouts_1.getPayoutTransfers; } });
__exportStar(require("./ETHAddress"), exports);
__exportStar(require("./fromEntries"), exports);
__exportStar(require("./mapValues"), exports);
__exportStar(require("./tryCast"), exports);
__exportStar(require("./sanitizers"), exports);
var earningsHistory_1 = require("./earningsHistory");
Object.defineProperty(exports, "toEarningsChartData", { enumerable: true, get: function () { return earningsHistory_1.toEarningsChartData; } });
Object.defineProperty(exports, "toChartData", { enumerable: true, get: function () { return earningsHistory_1.toChartData; } });
Object.defineProperty(exports, "toEarningsSummaryData", { enumerable: true, get: function () { return earningsHistory_1.toEarningsSummaryData; } });
Object.defineProperty(exports, "sumAllAfter", { enumerable: true, get: function () { return earningsHistory_1.sumAllAfter; } });
Object.defineProperty(exports, "pickMaxChartValue", { enumerable: true, get: function () { return earningsHistory_1.pickMaxChartValue; } });
__exportStar(require("./prepareSignedRequest"), exports);
var filterByTitle_1 = require("./filterByTitle");
Object.defineProperty(exports, "filterByTitle", { enumerable: true, get: function () { return filterByTitle_1.filterByTitle; } });
var getUserBalance_1 = require("./getUserBalance");
Object.defineProperty(exports, "getUserBalance", { enumerable: true, get: function () { return getUserBalance_1.getUserBalance; } });
var toFormattedDuration_1 = require("./toFormattedDuration");
Object.defineProperty(exports, "toFormattedDuration", { enumerable: true, get: function () { return toFormattedDuration_1.toFormattedDuration; } });
var getTotalBalance_1 = require("./getTotalBalance");
Object.defineProperty(exports, "getTotalBalance", { enumerable: true, get: function () { return getTotalBalance_1.getTotalBalance; } });
var getContractTitle_1 = require("./getContractTitle");
Object.defineProperty(exports, "getContractTitle", { enumerable: true, get: function () { return getContractTitle_1.getContractTitle; } });
