import React, { useState } from 'react'

import { ApiContract } from '@original-works/original-works-core/models'
import { LoadMoreButton } from '../../Components'
import { RightHoldersListItem } from './RightsHoldersListItem'

export interface RightsHoldersListProps {
  contract: ApiContract,
}

export function RightsHoldersList ({ contract }: RightsHoldersListProps) {
  const INITIAL_LIST_LENGTH = 5

  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <ol className="rights-holders-list">
        {contract.holders && contract.holders
          .slice(0, expanded ? contract.holders.length : INITIAL_LIST_LENGTH)
          .map(holder => (
            <RightHoldersListItem
              key={holder.address}
              holder={holder}
              totalSupply={contract.totalSupply}
            />
          ))
        }
      </ol>
      <LoadMoreButton
        hidden={expanded || contract.holders.length <= INITIAL_LIST_LENGTH}
        onClick={() => setExpanded(true)}
      />
    </>
  )
}
