import { useState, useEffect } from 'react'
import { useServices } from '../../ui/hooks'

export function useSessionId () {
  const { api } = useServices()
  const [sessionId, setSessionId] = useState<string | undefined>(undefined)
  useEffect(() => {
    let cancelled = false
    async function refreshSession () {
      const { id } = await api.createSession()
      if (!cancelled) {
        setSessionId(id)
      }
    }
    refreshSession()
    const interval = setInterval(refreshSession, 60 * 1000)
    return () => {
      cancelled = true
      clearInterval(interval)
    }
  }, [api])
  return sessionId
}
