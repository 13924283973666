import React from 'react'
import originalWorks from '../../assets/original-works.svg'
import arrow from '../../assets/arrow-right.svg'

export function SignInTop () {
  return (
    <div className="sign-in-top">
      <img className="sign-in-top__logo" src={originalWorks} />
      <img className="sign-in-top__arrow" src={arrow} alt="arrow" />
      <span className="sign-in-top__text">Sign in</span>
    </div>
  )
}
