"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTotalBalance = void 0;
const CurrencyValue_1 = require("./CurrencyValue");
function getTotalBalance(cryptoBalance, exchangeRates) {
    const currencies = cryptoBalance.map(formatCurrencies(exchangeRates));
    const total = currencies.reduce(calculateTotal, new CurrencyValue_1.CurrencyValue(0, 18, 'USD'));
    return {
        currencies,
        total,
    };
}
exports.getTotalBalance = getTotalBalance;
function formatCurrencies(exchangeRates) {
    return function (balanceByNetwork) {
        const balanceInUSD = new CurrencyValue_1.CurrencyValue(balanceByNetwork.balance.value.mul(exchangeRates[balanceByNetwork.balance.ticker]), 18, 'USD');
        const exchangeRate = new CurrencyValue_1.CurrencyValue(exchangeRates[balanceByNetwork.balance.ticker], 18, 'USD');
        return ({
            balance: balanceByNetwork.balance,
            balanceInUSD,
            exchangeRate,
            network: balanceByNetwork.networkName,
        });
    };
}
function calculateTotal(total, currency) {
    return total.add(currency.balanceInUSD);
}
