import dayjs from 'dayjs'

import { ETHAddress } from '@original-works/original-works-core/models'

import { Api } from './Api'
import { AccountService } from './AccountService'

export class TeostoPayoutService {
  constructor (
    private api: Api,
    private accountService: AccountService,
  ) { }

  async getAgreements () {
    const address = this.accountService.getAddress()
    return this.api.getAgreementsFor(address)
  }

  async getPayoutAmount (agreement: ETHAddress) {
    return this.api.getPayoutAmount(agreement, dayjs().subtract(1, 'day'))
  }
}
