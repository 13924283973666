import React from 'react'
import { bem } from 'bem-components-react'
import cx from 'classnames'

import { ApiAssetContract } from '@original-works/original-works-core/models'

import { AssetListItem } from './AssetListItem'

export interface AssetListProps {
  onlyShared: boolean,
  assets: ApiAssetContract[],
  user: string,
}

export function AssetList ({ assets, onlyShared, user }: AssetListProps) {
  return (
    <div className="contracts-list">
      <div
        className={cx(
          'contracts-list__header',
          'assets-list__header',
          onlyShared && 'assets-list__header--only-shared',
        )}
      >
        <ColumnHeader>Name</ColumnHeader>
        <ColumnHeader>Identifier</ColumnHeader>
        {onlyShared && <ColumnHeader>From</ColumnHeader>}
        <ColumnHeader right>Share</ColumnHeader>
        <ColumnHeader right>Pipeline revenue </ColumnHeader>
      </div>
      {assets.map(asset => (
        <AssetListItem
          key={asset.address}
          asset={asset}
          onlyShared={onlyShared}
          user={user}
        />
      ))}
    </div>
  )
}

const ColumnHeader = bem.p('assets-list__column-header contracts-list__column-header', ['right'])
