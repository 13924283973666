"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgreementQueryService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const utils_1 = require("../utils");
class AgreementQueryService {
    constructor(api, walletService, cacheService, refreshInterval) {
        this.api = api;
        this.walletService = walletService;
        this.agreementsCache = cacheService.createEntry();
        this.agreements = (0, rxjs_1.timer)(0, refreshInterval)
            .pipe((0, operators_1.switchMap)(() => (0, rxjs_1.from)(this.queryData()).pipe((0, operators_1.catchError)(() => rxjs_1.EMPTY))), (0, utils_1.shareCache)(this.agreementsCache), (0, utils_1.noUndefined)());
    }
    getAgreements() {
        return this.agreementsCache.get();
    }
    getAgreement(address) {
        const agreements = this.getAgreements();
        if (agreements === undefined)
            return undefined;
        return agreements.find(x => address.equals(x.address));
    }
    async queryData() {
        const address = this.walletService.getAddress();
        const agreements = await this.api.findContracts({
            holder: address,
            type: 'AGREEMENT',
        });
        return agreements.reverse();
    }
}
exports.AgreementQueryService = AgreementQueryService;
