import dayjs from 'dayjs'
import React from 'react'

import {
  ZERO_ADDRESS,
  toFormattedPercentage,
  formatNumber,
} from '@original-works/original-works-core/utils'
import {
  ApiActivityContract,
  ApiActivityContractTransferReceived,
  ApiContract,
} from '@original-works/original-works-core/models'

import { ContractIcon } from '../../../../../ui/common'
import { useAsync, useModal, useServices } from '../../../../../ui/hooks'
import { getContractIcon } from '../../../../../ui/utils'
import { SharesReceivedModal } from '../ActivityModals/SharesReceivedModal'
import { NormalText, HighlightedText, GrayText, Date, BoldText } from './components'

export interface SharesReceivedActivityListItemProps {
  activity: ApiActivityContractTransferReceived,
  recipient: ApiContract,
}

export function SharesReceivedActivityListItem ({ activity, recipient }: SharesReceivedActivityListItemProps) {
  const detailsModal = useModal()
  const { userService } = useServices()
  const isInitialShares = activity.from === ZERO_ADDRESS

  const [user] = useAsync(() => {
    if (!isInitialShares) {
      return userService.getUser(activity.from)
    }

    return Promise.resolve(undefined)
  }, [])

  const icon = getContractIcon(activity.contract)
  const title = `${isInitialShares ? getTitle(activity.contract) + '. ' : ''}` + 'Received'
  const value = toFormattedPercentage(activity.value, activity.contract.totalSupply.toString())
  const shares = formatNumber(activity.value) + (activity.value === '1' ? ' Share' : ' Shares')

  return (
    <li className="contract-activity-list-item" onClick={detailsModal.open}>
      <ContractIcon src={icon} />
      <div className="contract-activity-list-item__text">
        <NormalText>{title}</NormalText>
        <HighlightedText>{value}</HighlightedText>
        <GrayText>({shares})</GrayText>
        {!isInitialShares && (
          <>
            <NormalText>from</NormalText>
            <BoldText>{user ? user.name : '...'}</BoldText>
          </>
        )}
        <Date>
          {dayjs(activity.timestamp * 1000).format('DD MMM YYYY')}
        </Date>
      </div>
      <SharesReceivedModal
        isVisible={detailsModal.isVisible}
        onClose={detailsModal.close}
        title={isInitialShares ? getTitle(activity.contract) : 'Shares received'}
        user={user}
        activity={activity}
      />
    </li>
  )
}

function getTitle (contract: ApiActivityContract) {
  return contract.type === 'ASSET'
    ? 'Asset registered'
    : 'Agreement created'
}
