import { utils } from 'ethers'
import { IAccountService } from '@original-works/original-works-core/models'
import { BehaviorSubject, Observable } from 'rxjs'

import { PersistentStorage } from './PersistentStorage'

const STORAGE_KEY = 'userAddress'

export class AccountService implements IAccountService {
  private address: BehaviorSubject<string | null>

  constructor (private storage: PersistentStorage) {
    this.address = new BehaviorSubject(this.getStoredAddress())
  }

  getAddress () {
    const address = this.address.getValue()
    if (!address) {
      throw new Error('Account not initialized')
    }
    return address
  }

  isSignedIn () {
    return !!this.address.getValue()
  }

  getAddressObservable (): Observable<string | null> {
    return this.address
  }

  signIn (address: string, remember: boolean) {
    const properAddress = utils.getAddress(address)
    if (remember) {
      this.storage.setItem(STORAGE_KEY, properAddress)
    }
    this.address.next(properAddress)
  }

  signOut () {
    this.storage.removeItem(STORAGE_KEY)
    this.address.next(null)
  }

  private getStoredAddress () {
    try {
      return utils.getAddress(this.storage.getItem(STORAGE_KEY))
    } catch (e) {
      this.storage.removeItem(STORAGE_KEY)
      return null
    }
  }
}
