import React from 'react'
import TextareaAutosize from 'react-autosize-textarea'

import { Field } from '../../../ui/common'
import arrowIcon from '../../../assets/icons/arrow_white--long.svg'
import { Preview } from './Preview'
import { Creator } from './Creator'

const DESCRIPTION_PLACEHOLDER = 'e.g. All rights holders of this agreement are entitled to share ' +
  'in royalties received by my recording…'

export interface DescriptionStepProps {
  title: string,
  description: string,
  onChange (value: string): void,
  onSubmit (): void,
  goBack (): void,
}

export function DescriptionStep (props: DescriptionStepProps) {
  return (
    <Creator
      onSubmit={props.onSubmit}
      buttonContent={<>{props.description ? 'Next' : 'Skip'} <img src={arrowIcon} alt="arrow" /></>}
      twoColumns
    >
      <Preview title={props.title} onClick={props.goBack} />
      <Field label="Describe the agreement">
        <TextareaAutosize
          id="description"
          name="description"
          value={props.description}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => props.onChange(e.target.value)}
          className="form-textarea"
          placeholder={DESCRIPTION_PLACEHOLDER}
          maxRows={12}
          autoFocus
        />
      </Field>
    </Creator>
  )
}
