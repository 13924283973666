import React, { MouseEvent } from 'react'

import closeIcon from '../../assets/icons/close_light-blue.svg'

export interface PayToUserCloseButtonProps {
  onClick: (e: MouseEvent) => void,
}

export function PayToUserCloseButton ({ onClick }: PayToUserCloseButtonProps) {
  return (
    <button
      onClick={onClick}
      className="pay-to-user_modal-close-button"
    >
      <img src={closeIcon} alt="close" />
    </button>
  )
}
