import React from 'react'

import { ApiContract } from '@original-works/original-works-core/models'
import { formatNumber } from '@original-works/original-works-core/utils'

export interface DataPanelProps {
  contract: ApiContract,
}

export function DataPanel ({ contract }: DataPanelProps) {
  return (
    <div className="rights-holders-top">
      <div className="rights-holders-top__field">
        <span className="rights-holders-top__value">
          {formatNumber(contract.totalSupply)}
        </span>
        <span className="rights-holders-top__label">Shares</span>
      </div>
      <div className="rights-holders-top__field">
        <span className="rights-holders-top__value">{contract.holders.length}</span>
        <span className="rights-holders-top__label">Rights holders</span>
      </div>
      <div className="rights-holders-top__field">
        <span className="rights-holders-top__value">{contract.holders.filter(holder => holder.isAdmin).length}</span>
        <span className="rights-holders-top__label">Administrators</span>
      </div>
    </div>
  )
}
