import React from 'react'

import { GroupedList } from '@original-works/original-works-core/models'
import { groupDetailSetItems } from './utils'

export interface GroupedListProps {
  detailsSet: GroupedList,
}

export function GroupedListDisplay ({ detailsSet }: GroupedListProps) {
  const groupedItems = groupDetailSetItems(detailsSet)

  return (
    <div className="asset-info-details asset-info-details--grouped-list">
      {Object.keys(groupedItems).map((group, index) => (
        <div className="asset-info-details__item" key={index}>
          <span className="asset-info-details__header">{group}</span>
          {groupedItems[group].map((item, index) => (
            <div key={index} className="asset-info-details__record">
              <span className="asset-info-details__title">{item.title}</span>
              <span className="asset-info-details__info">{item.info}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
