import React, { useMemo } from 'react'
import { DataDisplay } from './DataDisplay'
import { XAxisLabels } from './XAxisLabels'
import { YAxisLabels } from './YAxisLabels'
import { toEarningsChartData, Period, pickMaxChartValue } from '@original-works/original-works-core/utils'
import { PayoutActivity } from '@original-works/original-works-core/services'
import { ApiFinancialFactsheet } from '@original-works/original-works-core/models'

export interface EarningsHistoryChartProps {
  period: Period,
  isAsset: boolean,
  payouts?: PayoutActivity[],
  financials?: ApiFinancialFactsheet,
}

export function EarningsHistoryChart ({ period, payouts, isAsset, financials }: EarningsHistoryChartProps) {
  const chartData = useMemo(() => toEarningsChartData(payouts, financials), [payouts, financials])

  const maxChartValue = useMemo(
    () => pickMaxChartValue(period === 'daily' ? chartData.daily : chartData.monthly),
    [period, chartData],
  )

  return (
    <div className="earnings-history-chart">
      <YAxisLabels maxChartValue={maxChartValue} />
      <XAxisLabels period={period} chartData={chartData} />
      <DataDisplay period={period} chartData={chartData} maxChartValue={maxChartValue} isAsset={isAsset}/>
    </div>
  )
}
