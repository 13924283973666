"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareSignedRequest = exports.JSON_HEADERS = void 0;
exports.JSON_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
async function prepareSignedRequest(wallet, url, method, timestamp, body) {
    // It is important to use {} in signature if body is missing,
    // because express sets body to {} for requests where it is missing
    const message = [method.toUpperCase(), url, JSON.stringify(body || {}), timestamp].join(':');
    const signature = await wallet.signMessage(message);
    const authorization = [signature, wallet.address, timestamp].join(':');
    return {
        method,
        headers: Object.assign(Object.assign({}, (body && exports.JSON_HEADERS)), { Authorization: authorization }),
        body: body ? JSON.stringify(body) : undefined,
    };
}
exports.prepareSignedRequest = prepareSignedRequest;
