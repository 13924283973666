import React from 'react'
import { bem } from 'bem-components-react'

import { ApiAgreementContract } from '@original-works/original-works-core/models'

import { AgreementListItem } from './AgreementListItem'

export interface AgreementListProps {
  onlyShared: boolean,
  agreements: ApiAgreementContract[],
  user: string,
}

export function AgreementList ({ agreements, onlyShared, user }: AgreementListProps) {
  return (
    <div className="contracts-list">
      <div className="contracts-list__header agreements-list__header">
        <ColumnHeader>Name</ColumnHeader>
        {!onlyShared && <ColumnHeader>Rights holders</ColumnHeader>}
        {onlyShared && <ColumnHeader>From</ColumnHeader>}
        <ColumnHeader>Created date</ColumnHeader>
        <ColumnHeader right>Share</ColumnHeader>
      </div>
      {agreements.map(agreement => (
        <AgreementListItem
          key={agreement.address}
          agreement={agreement}
          onlyShared={onlyShared}
          user={user}
        />
      ))}
    </div>
  )
}

const ColumnHeader = bem.p('contracts-list__column-header', ['right'])
