import React, { useState } from 'react'
import styled from 'styled-components'

import { useAsync, useServices } from '../../../ui/hooks'
import { AdminPage } from '../AdminPage'
import { MicroLendingTableHead } from './MicroLendingTableHead'
import { MicroLendingTableRow } from './MicroLendingTableRow'

export function MicroLending () {
  const { api } = useServices()
  const [lastId, setLastId] = useState<number>()
  const [loans] = useAsync(() => api.getLoans(), [lastId])

  const onApprove = async (id: number, interestRate: number) => {
    await api.approveLoan(id, interestRate)
    setLastId(id)
  }

  return (
    <AdminPage>
      <Container>
        <h1>Micro Lending</h1>
        <table>
          <MicroLendingTableHead/>
          <tbody>
            {loans && loans.reverse().map(loan => (
              <MicroLendingTableRow
                key={loan.id}
                loan={loan}
                onApprove={onApprove}
              />
            ))}
          </tbody>
        </table>
      </Container>
    </AdminPage>
  )
}

const Container = styled.div`
  font-size: 16px;
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  td {
    padding: 8px;
  }
`
