import React from 'react'
import cx from 'classnames'

import profileDefaultIcon from '../../assets/icons/profile.png'

export interface ProfileImageProps {
  src?: string | null,
  className?: string,
}

export function ProfileImage ({ src, className }: ProfileImageProps) {
  return <img src={src || profileDefaultIcon} alt="avatar" className={cx('avatar', className)} />
}
