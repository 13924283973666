import React from 'react'

import { UnorderedList } from '@original-works/original-works-core/models'

import { soundcloudIcon } from '../../../../assets/streamingPlatforms'

export interface UnorderedListDisplayProps {
  detailsSet: UnorderedList,
}

export function UnorderedListDisplay ({ detailsSet }: UnorderedListDisplayProps) {
  return (
    <ul className="asset-info-details asset-info-details--unordered-list">
      {detailsSet.items.map((item, index) => (
        <li className="asset-info-details__item" key={item.title + index}>
          <p className="asset-info-details__title">{item.title}</p>
          <p className="asset-info-details__artist">{item.artist}</p>
          <p className="asset-info-details__isrc">{item.isrc}</p>
          <div className="asset-info-details__info">
            {item.streamingPlatforms.slice(0, 3).map((platform) => (
              <a
                href={platform.link}
                key={platform.name}
                target="_blank"
                rel="noopener noreferrer"
                className="asset-info-details__streaming-platform"
              >
                <img src={getPlatformIcon(platform.name)} alt={platform.name} />
              </a>
            ))}
          </div>
          {/* TODO: Support more than 3 streaming platforms links */}
        </li>
      ))
      }
    </ul>
  )
}

function getPlatformIcon (platformName: string) {
  // TODO: add more icons in the future
  switch (platformName.toLowerCase()) {
    case 'soundcloud':
      return soundcloudIcon
    default:
      return undefined
  }
}
