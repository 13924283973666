import styled, { css } from 'styled-components'

import { COLORS } from '../../ui/colors'

interface TextProps {
  block?: boolean,
  bold?: boolean,
  blue?: boolean,
  gray?: boolean,
  darkBlue?: boolean,
}

export const Text = styled.span<TextProps>`
  ${({ block }) => block && css`display: block;`}
  ${({ bold }) => bold && css`font-weight: 500;`}

  color: ${COLORS.black};
  ${({ blue }) => blue && css`color: ${COLORS.lightBlue};`}
  ${({ gray }) => gray && css`color: ${COLORS.darkGray};`}
  ${({ darkBlue }) => darkBlue && css`color: ${COLORS.darkBlue};`}
`

export const H1 = styled(Text)`
  font-size: 48px;
  line-height: 54px;
`

export const H2 = styled(Text)`
  font-size: 26px;
  line-height: 34px;
`

export const H3 = styled(Text)`
  font-size: 26px;
  line-height: 34px;
`

export const H4 = styled(Text)`
  font-size: 20px;
  line-height: 26px;
`

export const H5 = styled(Text)`
  font-size: 18px;
  line-height: 26px;
`

export const H6 = styled(Text)`
  font-size: 16px;
  line-height: 24px;
`

export const H7 = styled(Text)`
  font-size: 14px;
  line-height: 20px;
`

export const H8 = styled(Text)`
  font-size: 12px;
  line-height: 16px;
`
