import React, { ReactNode, useState } from 'react'
import cx from 'classnames'

import spinnerIcon from '../../assets/icons/spinner-white.png'

export interface ButtonProps {
  children: ReactNode,
  onClick: () => void,
  disabled?: boolean,
  className?: string,
}

export function Button ({ children, onClick, className, disabled }: ButtonProps) {
  const [isLoading, setIsLoading] = useState(false)

  async function handleClick () {
    setIsLoading(true)
    await onClick()
  }

  return (
    <button
      className={cx('button', className)}
      onClick={handleClick}
      disabled={disabled || isLoading}
    >
      {isLoading
        ? <Spinner />
        : children
      }
    </button>
  )
}

function Spinner () {
  return <img src={spinnerIcon} alt="spinner" className="button_spinner" />
}
