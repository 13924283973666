import React from 'react'

import { ApiAgreement } from '@original-works/original-works-core/models'
import { CurrencyValue } from '@original-works/original-works-core/utils'
import { ExchangeRateService } from '@original-works/original-works-core/services'

import { Modal } from '../../../ui/common'
import { ActionQRCode } from '../../common/QRCode'
import { shorten } from '../../../ui/utils'
import { useServices } from '../../../ui/hooks'
import { getCover } from '../covers'
import { PayingView } from './PayingView'

export interface ProcessingProps {
  usdValue: CurrencyValue<'USD'>,
  agreement: ApiAgreement,
  onClose: () => void,
  onBack: () => void,
  eurValue?: CurrencyValue<'EUR'>,
  displayEUR?: boolean,
}

interface AmountFieldProps {
  usdValue: CurrencyValue<'USD'>,
}

interface PaymentFieldProps {
  ownValue: CurrencyValue<'OWN'>,
  usdValue: CurrencyValue<'USD'>,
  eurValue: CurrencyValue<'EUR'> | undefined,
  exchangeRateService: ExchangeRateService,
  address: string,
  title: string,
  displayEUR?: boolean,
}

interface QRCodeFieldProps {
  usdValue: CurrencyValue<'USD'>,
  address: string,
}

export function Confirm ({ usdValue, agreement, onClose, onBack, eurValue, displayEUR }: ProcessingProps) {
  const { exchangeRateService } = useServices()
  const ownValue = exchangeRateService.fromUSD(usdValue, 'OWN', 18)

  return (
    <Modal
      className="confirm"
      onClose={onClose}
      noClose={true}
    >
      <span className="confirm__send">Send {usdValue.format()} ({ownValue.format()})</span>
      <span className="confirm__edit" onClick={onBack}>Edit transaction</span>
      <AmountField usdValue={usdValue}/>
      <PaymentField
        ownValue={ownValue}
        usdValue={usdValue}
        eurValue={eurValue}
        exchangeRateService={exchangeRateService}
        address={agreement.address}
        title={agreement.title}
        displayEUR={displayEUR}
      />
      <QRCodeField usdValue={usdValue} address={agreement.address}/>
      <div className="confirm-field">
        <span className="confirm-field__info">Transaction time: Up to 3 minutes</span>
      </div>
      <div className="confirm-field"/>
    </Modal>
  )
}

function AmountField ({ usdValue }: AmountFieldProps) {
  return (
    <div className="confirm-field confirm-field--value">
      <span className="confirm-field__label">Amount</span>
      <p className="confirm-field-value">
        {usdValue.format()} <span className="confirm-field-value__ticker">USD</span>
      </p>
    </div>
  )
}

function PaymentField ({
  ownValue,
  eurValue,
  usdValue,
  exchangeRateService,
  address,
  title,
  displayEUR,
}: PaymentFieldProps) {
  return (
    <div className="confirm-field">
      <PayingView
        ownValue={ownValue}
        eurValue={eurValue || exchangeRateService.fromUSD(usdValue, 'EUR', 18)}
        displayEUR={displayEUR}
      />
      <span className="confirm-field__label">To</span>
      <div className="confirm-field-recipient">
        <img className="confirm-field-recipient__image" src={getCover(address)} />
        <span className="confirm-field-recipient__title">{title}</span>
        <span className="confirm-field__label">{shorten(address)}</span>
      </div>
    </div>
  )
}

function QRCodeField ({ address, usdValue }: QRCodeFieldProps) {
  return (
    <div className="confirm-field confirm-field--qr-code">
      <span className="confirm-field__scan-instruction">
        <strong>Scan this code</strong> from your phone to authorize and confirm this transaction.
      </span>
      <ActionQRCode action="royalty" value={[address, usdValue.value.toFixed(2)].join(':')} />
    </div>
  )
}
