import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useAsync, useServices } from '../../../ui/hooks'

import { PageNotFound } from '../../../ui/PageNotFound'
import { PayToUserPage } from '../PayToUserPage'
import { EnterAmount } from './EnterAmount'

export function InitiatePaymentPage () {
  const [mode] = useState<'amount' | 'method' | 'qrcode'>('amount')
  const params = useParams<{ id: string }>()
  const { userService } = useServices()

  const [user, error] = useAsync(() => userService.getUserByPaymentId(params.id), [])
  const [isPaymentEnabled] = useAsync(() => userService.isPaymentEnabled(params.id), [])

  if (!user && !error && !isPaymentEnabled) {
    return null
  }

  if ((!user && error) || error || isPaymentEnabled === false) {
    return <PageNotFound />
  }

  return (
    <PayToUserPage>
      {mode === 'amount' && user && <EnterAmount id={params.id} user={user} />}
    </PayToUserPage>
  )
}
