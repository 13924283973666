import React, { ReactNode, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import logo from '../../../assets/original-works.svg'
import { useServices, useSignIn, useSignOut } from '../../../ui/hooks'

import { NavbarDropdown } from './NavbarDropdown'

export interface NavBarTemplateProps {
  className?: string,
  children: ReactNode,
  alwaysVisible?: boolean,
}

export function NavBarTemplate ({ className, children, alwaysVisible }: NavBarTemplateProps) {
  const { visible, filled } = useNavbarControls(alwaysVisible)
  const { accountService } = useServices()
  const signIn = useSignIn()
  const signOut = useSignOut()

  const signedIn = accountService.isSignedIn()

  return (
    <div className={cx('navbar', visible && 'navbar--visible', filled && 'navbar--filled', className)}>
      <div className="navbar__background" />
      <div className="navbar__left">
        <NavbarDropdown
          signedIn={signedIn}
          signIn={signIn}
          signOut={signOut}
        />
        <Link className="navbar__logo" to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      {children}
    </div>
  )
}

function useNavbarControls (alwaysVisible?: boolean) {
  const [visible, setVisible] = useState(true)
  const [filled, setFilled] = useState(false)
  const lastScrollY = useRef(window.scrollY)

  useEffect(() => {
    function onScroll () {
      const previous = lastScrollY.current
      const current = Math.max(window.scrollY, 0)
      lastScrollY.current = current

      if (current === 0 && filled && !alwaysVisible) {
        setFilled(false)
      } else if (current !== 0 && !filled && !alwaysVisible) {
        setFilled(true)
      }

      if (previous > current && !visible) {
        setVisible(true)
      }
      if (previous < current && visible && !alwaysVisible) {
        setVisible(false)
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [visible, filled, alwaysVisible])

  return { visible, filled }
}
