"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asFactsheet = void 0;
const sanitizers_1 = require("@restless/sanitizers");
const asLiteral = (literal) => (0, sanitizers_1.asChecked)(sanitizers_1.asString, value => value === literal);
const asFieldList = (0, sanitizers_1.asObject)({
    name: sanitizers_1.asString,
    type: asLiteral('FIELD_LIST'),
    items: (0, sanitizers_1.asArray)((0, sanitizers_1.asObject)({
        title: sanitizers_1.asString,
        value: sanitizers_1.asString,
    })),
});
const asOrderedList = (0, sanitizers_1.asObject)({
    name: sanitizers_1.asString,
    type: asLiteral('ORDERED_LIST'),
    items: (0, sanitizers_1.asArray)((0, sanitizers_1.asObject)({
        title: sanitizers_1.asString,
        info: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
        type: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
        artist: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
        label: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
        duration: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
        isrc: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    })),
});
const asGroupedList = (0, sanitizers_1.asObject)({
    name: sanitizers_1.asString,
    type: asLiteral('GROUPED_LIST'),
    items: (0, sanitizers_1.asArray)((0, sanitizers_1.asObject)({
        title: sanitizers_1.asString,
        info: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
        group: sanitizers_1.asString,
    })),
});
const asUnorderedList = (0, sanitizers_1.asObject)({
    name: sanitizers_1.asString,
    type: asLiteral('UNORDERED_LIST'),
    items: (0, sanitizers_1.asArray)((0, sanitizers_1.asObject)({
        title: sanitizers_1.asString,
        artist: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
        isrc: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
        streamingPlatforms: (0, sanitizers_1.asArray)((0, sanitizers_1.asObject)({
            name: sanitizers_1.asString,
            link: sanitizers_1.asString,
        })),
    })),
});
exports.asFactsheet = (0, sanitizers_1.asObject)({
    type: sanitizers_1.asString,
    title: sanitizers_1.asString,
    subtitle: sanitizers_1.asString,
    artwork: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    enableRevenueCollection: sanitizers_1.asBoolean,
    explicit: (0, sanitizers_1.asOptional)(sanitizers_1.asBoolean),
    platform: (0, sanitizers_1.asObject)({
        name: sanitizers_1.asString,
        imageURL: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
        assetId: sanitizers_1.asString,
        enterpriseId: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    }),
    details: (0, sanitizers_1.asArray)((0, sanitizers_1.asAnyOf)([asFieldList, asGroupedList, asOrderedList, asUnorderedList], 'items list')),
    metadata: sanitizers_1.Result.ok,
});
