import React, { useState } from 'react'
import cx from 'classnames'

import { Field } from '../../../ui/common'
import arrowIcon from '../../../assets/icons/arrow_white--long.svg'
import { Creator } from './Creator'

export interface TitleStepProps {
  title: string,
  onSubmit (): void,
  onChange (value: string): void,
}

export function TitleStep (props: TitleStepProps) {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Creator
      onSubmit={props.onSubmit}
      title="Create an Agreement"
      subtitle="Create an agreement for your creative work, new or existing."
      disabled={!props.title}
      buttonContent={<>Next <img src={arrowIcon} alt="arrow" /></>}
    >
      <Field label="First give it a title">
        <div className={cx('form-input-wrapper', isFocused && 'active')}>
          <input
            className="form-input"
            type="text"
            name="title"
            placeholder="e.g. Recording Agreement"
            value={props.title}
            onChange={e => props.onChange(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            autoComplete="off"
            autoFocus
          />
          <p className="form-input-hint">Press Enter to continue</p>
        </div>
      </Field>
    </Creator>
  )
}
