import React from 'react'

import { useServices } from '../../../../ui/hooks'

export function NoPaymentsBar () {
  const { accountService } = useServices()

  return (
    <div className="no-payments-received-bar">
      <span className="no-payments-received-bar__text">
        {accountService.isSignedIn()
          ? 'There is no activity'
          : 'Sign in to view the activity of this agreement.'
        }
      </span>
    </div>
  )
}
