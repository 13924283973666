"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CachingSubject = void 0;
const rxjs_1 = require("rxjs");
class CachingSubject extends rxjs_1.Subject {
    constructor(cacheEntry) {
        super();
        this.cacheEntry = cacheEntry;
    }
    get value() {
        return this.getValue();
    }
    /** @deprecated This is an internal implementation detail, do not use. */
    _subscribe(subscriber) {
        // @ts-ignore
        const subscription = super._subscribe(subscriber);
        if (subscription && !subscription.closed) {
            subscriber.next(this.cacheEntry.get());
        }
        return subscription;
    }
    getValue() {
        if (this.hasError) {
            throw this.thrownError;
        }
        else if (this.closed) {
            throw new rxjs_1.ObjectUnsubscribedError();
        }
        else {
            return this.cacheEntry.get();
        }
    }
    next(value) {
        this.cacheEntry.set(value);
        super.next(value);
    }
}
exports.CachingSubject = CachingSubject;
