"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyValue = void 0;
const big_js_1 = require("big.js");
const numberFormatters_1 = require("./numberFormatters");
const ethers_1 = require("ethers");
class CurrencyValue {
    constructor(from, decimals, ticker) {
        this.decimals = decimals;
        this.ticker = ticker;
        this._value = (0, big_js_1.Big)(from).toFixed(decimals);
    }
    get value() {
        return (0, big_js_1.Big)(this._value);
    }
    static fromWei(value, decimals, ticker) {
        const bigValue = (0, big_js_1.Big)(value.toString()).div((0, big_js_1.Big)(10).pow(decimals));
        return new CurrencyValue(bigValue, decimals, ticker);
    }
    add(other) {
        if (this.ticker !== other.ticker) {
            throw new Error('Incompatible tickers');
        }
        return new CurrencyValue(this.value.add(other.value), this.decimals, this.ticker);
    }
    sub(other) {
        if (this.ticker !== other.ticker) {
            throw new Error('Incompatible tickers');
        }
        return new CurrencyValue(this.value.sub(other.value), this.decimals, this.ticker);
    }
    mul(value) {
        return new CurrencyValue(this.value.mul(value), this.decimals, this.ticker);
    }
    div(value) {
        return new CurrencyValue(this.value.div(value), this.decimals, this.ticker);
    }
    equals(other) {
        if (this.ticker !== other.ticker) {
            throw new Error('Incompatible tickers');
        }
        return this.value.eq(other.value) && this.ticker === other.ticker && this.decimals === other.decimals;
    }
    gt(other) {
        if (this.ticker !== other.ticker) {
            throw new Error('Incompatible tickers');
        }
        return this.value.gt(other.value);
    }
    lt(other) {
        if (this.ticker !== other.ticker) {
            throw new Error('Incompatible tickers');
        }
        return this.value.lt(other.value);
    }
    gte(other) {
        if (this.ticker !== other.ticker) {
            throw new Error('Incompatible tickers');
        }
        return this.value.gte(other.value);
    }
    lte(other) {
        if (this.ticker !== other.ticker) {
            throw new Error('Incompatible tickers');
        }
        return this.value.lte(other.value);
    }
    isZero() {
        return this.value.eq(0);
    }
    format() {
        return (0, numberFormatters_1.formatCurrency)(this.value.abs(), this.ticker);
    }
    formatWithSign() {
        const value = this.value;
        const sign = value.lt(0) ? '-' : value.gt(0) ? '+' : '';
        return `${sign}${(0, numberFormatters_1.formatCurrency)(value.abs(), this.ticker)}`;
    }
    formatWithTicker() {
        return (0, numberFormatters_1.formatWithTicker)(this.value.abs(), this.ticker);
    }
    formatSymbolTicker() {
        if (this.ticker === 'USD') {
            return `${this.format()} ${this.ticker}`;
        }
        else {
            return this.format();
        }
    }
    formatSignSymbolTicker() {
        if (this.ticker === 'USD') {
            return `${this.formatWithSign()} ${this.ticker}`;
        }
        else {
            return this.formatWithSign();
        }
    }
    toWei() {
        return ethers_1.BigNumber.from((0, big_js_1.Big)(this._value)
            .mul((0, big_js_1.Big)(10)
            .pow(this.decimals))
            .toFixed(0));
    }
    toJSON() {
        return {
            value: this._value,
            decimals: this.decimals,
            ticker: this.ticker,
        };
    }
}
exports.CurrencyValue = CurrencyValue;
