import React, { useState } from 'react'
import { providers, utils, BigNumber } from 'ethers'
import { useDashboard, useAgreementContract } from './useDashboard'
import styled from 'styled-components'
import { Button } from '../Button'
import { useAsync } from '../../../ui/hooks'

export interface DashboardProps {
  provider: providers.JsonRpcProvider,
  account: string,
  network: string,
}

export function FeeDashboard ({ provider, account, network }: DashboardProps) {
  const {
    yourBalance,
    factory,
    factoryBalance,
    factoryOwner,
    feeManager,
    feeManagerBalanceEth,
    feeManagerBalanceOwn,
    feeManagerOwner,
    creationFee,
    paymentFee,
    contracts,
    collectFactoryFee,
    withdrawEth,
    withdrawOwn,
    setCreationFee,
    setPaymentFee,
    collectAgreementFee,
  } = useDashboard(provider, account)

  const [creationFeeValue, setCreationFeeValue] = useState('0.0')
  async function onCreationFeeSubmit (e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    await setCreationFee(utils.parseEther(creationFeeValue))
  }

  const [paymentFeeValue, setPaymentFeeValue] = useState('0.0')
  async function onPaymentFeeSubmit (e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    await setPaymentFee(utils.parseEther(paymentFeeValue).div(100))
  }

  return (
    <div>
      <h1>Fees</h1>
      <p>Network: {network}</p>

      <Block>
        <Title>Your account</Title>
        <Group>
          <p>Address: {account}</p>
          <p>Balance: ETH {yourBalance ? utils.formatEther(yourBalance) : '...'}</p>
        </Group>
      </Block>

      <Block>
        <Title>Factory</Title>
        <Group>
          <p>Address: {factory.address}</p>
          <p>Owner: {factoryOwner}</p>
          <p>Balance: ETH {factoryBalance ? utils.formatEther(factoryBalance) : '...'}</p>
        </Group>
        <Button onClick={collectFactoryFee}>Collect fee</Button>
      </Block>

      <Block>
        <Title>Fee Manager</Title>
        <Group>
          <p>Address: {feeManager.address}</p>
          <p>Owner: {feeManagerOwner}</p>
          <p>Balance: ETH {feeManagerBalanceEth ? utils.formatEther(feeManagerBalanceEth) : '...'}</p>
          <p>Balance: OWN {feeManagerBalanceOwn ? utils.formatEther(feeManagerBalanceOwn) : '...'}</p>
          <p>Creation fee: ETH {creationFee ? utils.formatEther(creationFee) : '...'}</p>
          <p>Payment fee: {paymentFee ? utils.formatEther(paymentFee.mul(100)) : '...'}%</p>
        </Group>
        <Group>
          <Button onClick={withdrawEth}>Withdraw ETH</Button>
          {' '}
          <Button onClick={withdrawOwn}>Withdraw OWN</Button>
        </Group>
        <FormGroup onSubmit={onCreationFeeSubmit}>
          <input value={creationFeeValue} onChange={e => setCreationFeeValue(e.target.value)} />
          <Button type="submit">Set creation fee</Button>
        </FormGroup>
        <FormGroup onSubmit={onPaymentFeeSubmit}>
          <input value={paymentFeeValue} onChange={e => setPaymentFeeValue(e.target.value)} />
          <Button type="submit">Set payment fee</Button>
        </FormGroup>
      </Block>

      <Block>
        <Title>Contracts</Title>
        {contracts && contracts.map(({ address, title }) =>
          <ContractEntry
            key={address}
            address={address}
            title={title}
            provider={provider}
            collectFee={() => collectAgreementFee(address)}
          />,
        )}
      </Block>
    </div>
  )
}

interface ContractEntryProps {
  address: string,
  title: string,
  provider: providers.JsonRpcProvider,
  collectFee: () => void,
}

function ContractEntry (props: ContractEntryProps) {
  const contract = useAgreementContract(props.address, props.provider)
  const [fee] = useAsync((): Promise<BigNumber> => contract.getAvailableFee(), [contract])

  return (
    <Group>
      <AgreementTitle>{props.title}</AgreementTitle>
      <p>{props.address}</p>
      <p>Available fee: OWN {fee ? utils.formatEther(fee) : '...'}</p>
      <p><Button onClick={props.collectFee}>Collect fee</Button></p>
    </Group>
  )
}

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 10px;
`

const AgreementTitle = styled.h3`
  margin: 0;
`

const Group = styled.div`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`

const FormGroup = Group.withComponent('form')

const Block = styled.div`
  background-color: white;
  border-radius: 4px;
  margin: 20px 0;
  padding: 20px;

  form {
    display: flex;
    align-items: center;
  }

  input {
    background-color: white;
    border: 1px solid gray;
    padding: 8px;
    font-size: inherit;
    border-radius: 4px;
    margin-right: 10px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
`
