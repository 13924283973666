import React from 'react'

import { ApiAgreementContract, ApiAssetContract, ApiContract } from '@original-works/original-works-core/models'
import { useServices } from '../../../ui/hooks'

import { AgreementList } from './AgreementList'
import { AssetList } from './AssetList'

export interface ContractListProps {
  contracts: ApiContract[],
  onlyShared: boolean,
  type: 'ASSET' | 'AGREEMENT',
}

export function ContractList ({ contracts, onlyShared, type }: ContractListProps) {
  const { accountService } = useServices()
  const address = accountService.getAddress()
  const assets = contracts.filter(isAsset)
  const agreements = contracts.filter(isAgreement)

  return type === 'ASSET'
    ? <AssetList assets={assets} user={address} onlyShared={onlyShared} />
    : <AgreementList agreements={agreements} user={address} onlyShared={onlyShared} />
}

function isAsset (contract: ApiContract): contract is ApiAssetContract {
  return contract.type === 'ASSET'
}

function isAgreement (contract: ApiContract): contract is ApiAgreementContract {
  return !isAsset(contract)
}
