import React from 'react'

import { ApiContract } from '@original-works/original-works-core/models'
import { Activity } from '@original-works/original-works-core/services'

import { PaymentsListItem } from './PaymentsListItem'
import { AdminChangedActivityListItem } from './AdminChangedActivityListItem'
import { SharesReceivedActivityListItem } from './SharesReceivedActivityListItem'

export interface ActivityListItemProps {
  activity: Activity,
  contract: ApiContract,
}

export function ActivityListItem ({ activity, contract }: ActivityListItemProps) {
  switch (activity.type) {
    case 'PAYOUT':
      return <PaymentsListItem contract={contract} payout={activity} />
    case 'ADMIN_ADDED':
    case 'ADMIN_REMOVED':
      return <AdminChangedActivityListItem activity={activity} contract={contract} />
    case 'AGREEMENT_TRANSFER_RECEIVED':
      return <SharesReceivedActivityListItem activity={activity} recipient={contract} />
    default:
      return null
  }
}
