import React from 'react'
import cx from 'classnames'

import agreementIcon from '../../../../../../assets/icons/agreement.svg'
import { ApiAgreementContract } from '@original-works/original-works-core/models'

export interface AgreementFieldProps {
  contract: ApiAgreementContract,
  title: string,
  bottom?: boolean,
}

export function AgreementField ({ contract, title, bottom }: AgreementFieldProps) {
  return (
    <div className={cx('activity-modal-field', bottom && 'activity-modal-field--bottom')}>
      <span className="activity-modal-field__title">{title}</span>
      <div className="activity-modal-contract__wrapper">
        <img src={agreementIcon} alt="icon" className="activity-modal-contract__icon"/>
        <div className="activity-modal-contract__description">
          <p className="activity-modal-contract__title">
            {contract.title}
          </p>
          <p className="activity-modal-contract__text">
            {contract.holders.length === 1 && 'Only you'}
            {contract.holders.length === 2 && 'With you & 1 other'}
            {contract.holders.length > 2 && `With you & ${contract.holders.length - 1} others`}
          </p>
        </div>
      </div>
    </div>
  )
}
