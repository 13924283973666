import React from 'react'

import { ApiContract } from '@original-works/original-works-core/models'
import { TransferHistoryList } from './TransferHistoryList'

export interface TransferHistoryProps {
  contract: ApiContract,
}

export function TransferHistory ({ contract }: TransferHistoryProps) {
  return (
    <div className="transfer-history__wrapper">
      <h2 className="transfer-history__title">Transfer history</h2>
      <div className="transfer-history-list__header">
        <span className="transfer-history-list__label">From</span>
        <span className="transfer-history-list__label">To</span>
        <span className="transfer-history-list__label">Date</span>
        <span className="transfer-history-list__label">Amount</span>
      </div>
      <TransferHistoryList contract={contract}/>
    </div>
  )
}
