import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'

import { useServices, useObservable } from '../../ui/hooks'
import { SignInScreen } from './SignInScreen'
import { useSessionId } from './useSessionId'

interface LocationState {
  from: string,
}

export function SignIn () {
  const { api, accountService } = useServices()
  const [remember, setRemember] = useState(true)
  const location = useLocation<LocationState>()

  const [accountAddress] = useObservable(() => accountService.getAddressObservable(), [])
  const sessionId = useSessionId()

  useEffect(() => {
    if (sessionId) {
      const timer = setInterval(async () => {
        const { address } = await api.getSession(sessionId)
        try {
          address && accountService.signIn(address, remember)
        } catch (e) {
          console.error(e)
          alert('Error during login')
        }
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [accountService, api, sessionId, remember])

  const origin = location.state?.from

  if (!origin) {
    return <Redirect to='/'/>
  }

  if (accountAddress && origin) {
    return <Redirect to={origin}/>
  } else {
    return (
      <SignInScreen
        sessionId={sessionId}
        remember={remember}
        setRemember={setRemember}
      />
    )
  }
}
