import React from 'react'
import ReactPlayer from 'react-player'

import { ActionQRCode } from '../../ui/common/QRCode'
import { SignInTop } from './SignInTop'
import { SignInInstructions } from './SignInInstructions'
import { SignInCheckbox } from './SignInCheckbox'
import { DevSignInButton } from './DevSignInButton'

export interface SignInScreenProps {
  sessionId: string | undefined,
  remember: boolean,
  setRemember: (remember: boolean) => void,
}

export function SignInScreen ({ sessionId, remember, setRemember }: SignInScreenProps) {
  return (
    <div className="sign-in">
      <div className="sign-in__left">
        <SignInTop />
        <div className="sign-in__content">
          <h1 className="sign-in__title">Sign in</h1>
          <span className="sign-in__subtitle">
            Follow the instructions below to sign in to your account.
          </span>
          <div className="sign-in__scan">
            {sessionId && <ActionQRCode action="pairing" value={sessionId} />}
          </div>
          <SignInCheckbox remember={remember} setRemember={setRemember}/>
          {sessionId && <DevSignInButton sessionId={sessionId} />}
          <SignInInstructions />
        </div>
      </div>
      <div className="sign-in__right">
        <ReactPlayer url="https://www.youtube.com/watch?v=tIV6tGaAqfA" />
      </div>
    </div>
  )
}
