import React from 'react'

import { ApiContract } from '@original-works/original-works-core/models'
import { DataPanel } from './DataPanel'
import { RightsHoldersList } from './RightsHoldersList'

export interface RightHoldersProps {
  contract: ApiContract,
}

export function RightsHolders ({ contract }: RightHoldersProps) {
  return (
    <div className="rights-holders__wrapper">
      <h2 className="rights-holders__title">Rights holders</h2>
      <DataPanel contract={contract}/>
      <div className="rights-holders-list__header">
        <span className="rights-holders-list__label">Name</span>
        <span className="rights-holders-list__label">Wallet Address</span>
        <span className="rights-holders-list__label">Share of Agreement</span>
      </div>
      <RightsHoldersList contract={contract}/>
    </div>
  )
}
