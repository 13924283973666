import React, { useEffect } from 'react'
import { AdminPage } from '../AdminPage'
import { useMetamask } from './useMetamask'
import { FeeDashboard } from './FeeDashboard'
import styled from 'styled-components'
import { useConfig } from '../../../ui/hooks'
import { Button } from '../Button'

export function AdminFees () {
  const { state, enable } = useMetamask()
  const { network } = useConfig()
  useEffect(() => console.log(state), [state])

  if (state.type === 'NO_ETHEREUM') {
    return (
      <AdminPage>
        <ErrorDisplay>
          No ethereum provider (e.g. Metamask) found.
          Please install it from:{' '}
          <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
            the Metamask website
          </a>.
        </ErrorDisplay>
      </AdminPage>
    )
  }

  if (state.type !== 'ENABLED') {
    return (
      <AdminPage>
        <ButtonWithMargin
          onClick={enable}
          disabled={state.type === 'ENABLING'}
        >
          Enable Metamask
        </ButtonWithMargin>
        {state.type === 'ERROR' && (
          <ErrorDisplay>Error: {state.message}</ErrorDisplay>
        )}
      </AdminPage>
    )
  }

  if (state.network !== network) {
    return (
      <AdminPage>
        <ErrorDisplay>
          Please change the network to <strong>{network}</strong>.
        </ErrorDisplay>
      </AdminPage>
    )
  }

  return (
    <AdminPage>
      <FeeDashboard {...state} />
    </AdminPage>
  )
}

const ErrorDisplay = styled.p`
  margin: 20px 0;
  padding: 15px;
  background-color: #ffd8d8;
  color: #920000;
  border-radius: 4px;

  a {
    color: #630000;
    text-decoration: underline;
  }
`

const ButtonWithMargin = styled(Button)`
  margin-top: 20px;
`
