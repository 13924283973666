"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBetween = exports.makeArray = void 0;
function makeArray(length, constructor) {
    return new Array(length).fill(0).map((_, i) => constructor(i));
}
exports.makeArray = makeArray;
function isBetween(date, inclusiveFrom, exclusiveTo) {
    return (date.isSame(inclusiveFrom) || date.isAfter(inclusiveFrom)) &&
        (!exclusiveTo || date.isBefore(exclusiveTo));
}
exports.isBetween = isBetween;
