import React from 'react'
import qrcode from 'qrcode'
import styled from 'styled-components'

import { useAsync } from '../../../ui/hooks'

export const AgreementQR = ({ data }: { data: string }) => {
  const [dataUrl] = useAsync(() => qrcode.toDataURL(data), [data])

  return (
    <QRCode src={dataUrl || ''} alt="QRCode" onClick={() => console.log(data)} />
  )
}

const QRCode = styled.img`
  display: flex;
  align-self: center;
`
