"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterByTitle = void 0;
function filterByTitle(searchString) {
    searchString = searchString.toLowerCase();
    return function (element) {
        const title = element.title.toLowerCase();
        return title.includes(searchString);
    };
}
exports.filterByTitle = filterByTitle;
