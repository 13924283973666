"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractActivityService = void 0;
class ContractActivityService {
    constructor(api, payoutQueryService) {
        this.api = api;
        this.payoutQueryService = payoutQueryService;
    }
    async getActivityFor(address) {
        const payouts = await this.payoutQueryService.getPayouts(address);
        const activity = await this.getApiContractActivity(address);
        return [].concat(payouts.activity, activity).sort(sortByTimestamp);
    }
    async getApiContractActivity(address) {
        return this.api.getContractActivityFor(address);
    }
}
exports.ContractActivityService = ContractActivityService;
function sortByTimestamp(activityA, activityB) {
    return activityB.timestamp - activityA.timestamp;
}
