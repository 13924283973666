"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkCapabilities = exports.EnvironmentName = exports.NetworkName = void 0;
var NetworkName;
(function (NetworkName) {
    NetworkName["ropsten"] = "ropsten";
    NetworkName["kovan"] = "kovan";
    NetworkName["ownet"] = "ownet";
    NetworkName["mainnet"] = "mainnet";
    NetworkName["binance"] = "binance";
    NetworkName["local"] = "local";
    NetworkName["test"] = "test";
    NetworkName["polygon"] = "polygon";
    NetworkName["goerli"] = "goerli";
    NetworkName["mumbai"] = "mumbai";
})(NetworkName = exports.NetworkName || (exports.NetworkName = {}));
var EnvironmentName;
(function (EnvironmentName) {
    EnvironmentName["dev"] = "devel";
    EnvironmentName["stage"] = "stage";
    EnvironmentName["prod"] = "prod";
    EnvironmentName["test"] = "test";
    EnvironmentName["local"] = "local";
})(EnvironmentName = exports.EnvironmentName || (exports.EnvironmentName = {}));
var NetworkCapabilities;
(function (NetworkCapabilities) {
    NetworkCapabilities["NFT"] = "NFT";
    NetworkCapabilities["Agreements"] = "Agreements";
})(NetworkCapabilities = exports.NetworkCapabilities || (exports.NetworkCapabilities = {}));
