"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBalanceIn = exports.EMPTY_BALANCE = exports.BalanceService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const utils_1 = require("../utils");
class BalanceService {
    constructor(cacheService, balanceChecker, exchangeRateService, refreshInterval) {
        this.balanceChecker = balanceChecker;
        this.balanceCache = cacheService.createEntry(exports.EMPTY_BALANCE);
        this.balance = (0, rxjs_1.timer)(0, refreshInterval).pipe((0, operators_1.switchMap)(() => this.balanceChecker.getCryptoBalance().catch(() => undefined)), (0, utils_1.noUndefined)(), (0, operators_1.map)(balance => (0, utils_1.getTotalBalance)(balance, exchangeRateService.getExchangeRates())), (0, utils_1.shareCache)(this.balanceCache));
        this.subscribe = (0, utils_1.toSubscribeMethod)(this.balance);
    }
    getTotalBalance() {
        return this.balanceCache.get();
    }
    getBalanceIn(ticker, networkName) {
        return getBalanceIn(this.getTotalBalance(), ticker, networkName);
    }
}
exports.BalanceService = BalanceService;
exports.EMPTY_BALANCE = {
    currencies: [],
    total: new utils_1.CurrencyValue(0, 18, 'USD'),
};
function getBalanceIn(balance, ticker, networkName) {
    const currency = balance.currencies.find(currency => currency.balance.ticker === ticker && currency.network === networkName);
    if (currency) {
        return currency;
    }
    else {
        return {
            balance: new utils_1.CurrencyValue(0, 18, ticker),
            balanceInUSD: new utils_1.CurrencyValue(0, 18, 'USD'),
            exchangeRate: new utils_1.CurrencyValue(0, 18, 'USD'),
            network: networkName,
        };
    }
}
exports.getBalanceIn = getBalanceIn;
