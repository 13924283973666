"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPayoutTransfers = void 0;
const ethers_1 = require("ethers");
const sumBalances = (balances) => balances.reduce((total, { balance }) => total.add(balance), ethers_1.BigNumber.from(0));
function calculatePayouts(amount, balances, refundAddress) {
    const totalBalances = sumBalances(balances);
    const totalPayouts = ethers_1.BigNumber.from(amount);
    let balancesLeft = totalPayouts;
    if (totalBalances.eq(0)) {
        return amount === '0' ? [] : [{ address: refundAddress, value: amount }];
    }
    const payouts = balances.map(({ address, balance }) => {
        const value = ethers_1.BigNumber.from(balance)
            .mul(totalPayouts)
            .div(totalBalances);
        balancesLeft = balancesLeft.sub(value);
        return { address, value: value.toString() };
    });
    const refund = payouts.find(({ address }) => address === refundAddress);
    if (refund) {
        refund.value = balancesLeft.add(refund.value).toString();
    }
    else if (refundAddress) {
        payouts.unshift({ address: refundAddress, value: balancesLeft.toString() });
    }
    return payouts.filter(transfer => transfer.value !== '0');
}
function getPayoutTransfers(amount, holders) {
    return calculatePayouts(amount, holders, holders[0] && holders[0].address);
}
exports.getPayoutTransfers = getPayoutTransfers;
