import { useHistory } from 'react-router-dom'
import { useServices } from '../../ui/hooks/useServices'

export function useSignOut () {
  const history = useHistory()
  const { accountService } = useServices()

  return () => {
    accountService.signOut()
    history.push('/')
  }
}
