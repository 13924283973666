import React from 'react'

import { getContractTitle } from '@original-works/original-works-core/utils'

import { ProfileImage } from '../../../../ui/common'
import { useServices, useUserOrContract } from '../../../../ui/hooks'
import { getContractIcon } from '../../../../ui/utils'

export interface HolderProfileProps {
  holderAddress: string,
}

export function HolderProfile ({ holderAddress }: HolderProfileProps) {
  const { accountService } = useServices()
  const { user, contract } = useUserOrContract(holderAddress, [])
  const name = contract ? getContractTitle(contract) : user?.name || '...'

  return (
    <div className="holder-profile">
      {contract
        ? <img src={getContractIcon(contract)} alt="contract" className="holder-profile__contract-icon" />
        : <ProfileImage src={user?.imageURL} />
      }
      <p className="holder-profile__name">
        {name}
        {accountService.isSignedIn() && accountService.getAddress() === holderAddress && ' (You)'}
      </p>
    </div>
  )
}
