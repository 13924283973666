import React from 'react'
import { toFormattedPercentage } from '@original-works/original-works-core/utils'

export interface SharesFieldProps {
  value: string,
  totalSupply: string,
}

export function SharesField ({ value, totalSupply }: SharesFieldProps) {
  const percentage = toFormattedPercentage(value, totalSupply)

  return (
    <div className="activity-modal-field">
      <p className="activity-modal-field__title">Shares</p>
      <div className="activity-modal-shares__wrapper">
        <p className="activity-modal-shares">{percentage}</p>
      </div>
    </div>
  )
}
