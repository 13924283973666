import { ICacheEntry } from '@original-works/original-works-core/models'

export class CacheEntry<T> implements ICacheEntry<T> {
  constructor (
    private temporaryKeyStorage: Record<string, T | undefined>,
    private key: string,
    private initialValue: T,
  ) {
  }

  get () {
    if (Object.prototype.hasOwnProperty.call(this.temporaryKeyStorage, this.key)) {
      return this.temporaryKeyStorage[this.key]!
    } else {
      return this.initialValue
    }
  }

  set (value: T) {
    this.temporaryKeyStorage[this.key] = value
  }

  clear () {
    delete this.temporaryKeyStorage[this.key]
  }
}
