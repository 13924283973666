"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BalanceChecker = void 0;
const ethers_1 = require("ethers");
const utils_1 = require("../utils");
class BalanceChecker {
    constructor(addressHolder, provider, contractAddress, nativeCoin) {
        this.addressHolder = addressHolder;
        this.provider = provider;
        this.contractAddress = contractAddress;
        this.nativeCoin = nativeCoin;
    }
    async getNativeCoinBalance() {
        const address = this.addressHolder.getAddress();
        const networkName = (await this.provider.getNetwork()).name;
        const balanceInWei = await this.provider.getBalance(address);
        return {
            balance: utils_1.CurrencyValue.fromWei(balanceInWei, 18, this.nativeCoin),
            networkName,
        };
    }
    async getTokenBalance() {
        const abi = [
            'function balanceOf(address owner) view returns(uint256)',
        ];
        const address = this.addressHolder.getAddress();
        const networkName = (await this.provider.getNetwork()).name;
        const contract = new ethers_1.Contract(this.contractAddress, abi, this.provider);
        const balanceInWei = await contract.balanceOf(address);
        return {
            balance: utils_1.CurrencyValue.fromWei(balanceInWei, 18, 'OWN'),
            networkName,
        };
    }
    async getCryptoBalance() {
        if (this.nativeCoin === 'OWN') {
            return [await this.getTokenBalance()];
        }
        return [
            await this.getTokenBalance(),
            await this.getNativeCoinBalance(),
        ];
    }
}
exports.BalanceChecker = BalanceChecker;
