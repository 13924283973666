import React from 'react'
import { NavBarTemplate } from '../../ui/common/NavBar/NavBarTemplate'

export function PayToUserNavBar () {
  const href = navigator.userAgent.includes('iPhone') ? 'https://apple.co/2VVvUSq' : 'https://original.works'

  return (
    <NavBarTemplate alwaysVisible>
      <div className="navbar__get-app-field">
        <a className="navbar__get-app-link" href={href}>Get the app</a>
      </div>
    </NavBarTemplate>
  )
}
