import React from 'react'
import { useLocation } from 'react-router-dom'

import { Footer, NavBar } from '../../ui/common'
import { useRedirectHome } from '../../ui/hooks'
import { PageNotFound } from '../../ui/PageNotFound'

export function EmailConfirmationError () {
  const goHome = useRedirectHome()
  const location = useLocation()
  const text = getErrorText(location.search.slice(1))

  if (!text) {
    return <PageNotFound />
  }

  return (
    <div className="page">
      <NavBar className="beige" />
      <div className="email-not-confirmed">
        <p className="email-not-confirmed__main-text">Oops!..</p>
        <p className="email-not-confirmed__main-text--highlight">{text}</p>
        <p className="email-not-confirmed__note">
          We’ll try to automatically redirect you in a few seconds, or you can hit the button below to return home.
        </p>
        <button className="button email-confirmed__home-button" onClick={goHome}>Go home</button>
      </div>
      <Footer />
    </div>
  )
}

function getErrorText (reason: string) {
  switch (reason) {
    case 'EMAIL_ALREADY_VERIFIED':
      return 'You already verified this email.'
    case 'EMAIL_ALREADY_EXISTS':
      return 'Verification failed. Email is already used.'
    case 'INCORRECT_CREDENTIALS':
      return 'Verification failed. Incorrect credentials.'
    default:
      return undefined
  }
}
