import React, { useState } from 'react'
import styled from 'styled-components'

import { PayoutActivity } from '@original-works/original-works-core/services'

import { ActivityListItem } from './ActivityListItem'

export interface AgreementListOfActivityProps {
  activities: PayoutActivity[],
  agreementName: string,
  agreementOwner: string,
}

export const AgreementListOfActivity = ({
  activities,
  agreementName,
  agreementOwner,
}: AgreementListOfActivityProps) => {
  const [limit, setLimit] = useState(10)
  const [loaded, setLoaded] = useState(true)

  const activityList = activities.slice(0, limit)

  return (
    <List>
      <Title>Royalty payments</Title>
      <Heading>
        <Top>Date</Top>
        <Top>Transaction</Top>
      </Heading>
      {activityList.map((item, idx) => (
        <ActivityListItem
          key={idx}
          agreementName={agreementName}
          {...item}
          agreementOwner={agreementOwner}
        />
      ))}
      {activities.length > 10 && loaded &&
        <BottomButton
          onClick={() => {
            setLimit(activities.length)
            setLoaded(false)
          }}
        >
          Load more
        </BottomButton>
      }
    </List>
  )
}

const Title = styled.p`
  margin-top: 60px;
`
const List = styled.div`
  display: grid;
  grid-row: 3;
  grid-gap: 10px;
  max-width: 836px;
  margin: auto;
  padding-bottom: 80px;
`

const Heading = styled.div`
  display: grid;
  grid-template-columns: 110px 40px 4fr 106px;
  height: 30px;
  align-items: flex-end;
`
const Top = styled.p`
  font-size: 12px;
  line-height: 1.33;
  color: #888888;
  &:first-of-type{
    padding: 0 0 0 10px;
  }
`

const BottomButton = styled.button`
  margin-top: 10px;
  width: 130px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #7341f3;
  font-size: 16px;
  line-height: 1.5;
  color: #7341f3;
  &:hover {
    background-color: #7341f3;
    color: #fff;
  }
`
