import { useMemo } from 'react'
import { providers, Contract, BigNumber, BigNumberish } from 'ethers'
import { useConfig, useAsync, useServices } from '../../../ui/hooks'
import FeeManagerJSON from '@original-works/original-works-ethereum/build/v7/FeeManager.json'
import FactoryJSON from '@original-works/original-works-ethereum/build/v7/AgreementFactory.json'
import OwnCoinJSON from '@original-works/original-works-ethereum/build/v7/OWNCoin.json'
import AgreementJSON from '@original-works/original-works-ethereum/build/v7/Agreement.json'

const ETH_TOKEN_ADDRESS = '0x' + 'e'.repeat(40)

export function useDashboard (provider: providers.JsonRpcProvider, account: string) {
  const { factoryAddress, feeManagerAddress, OWNTokenAddress } = useConfig()
  const { api } = useServices()

  const factory = useFactoryContract(factoryAddress, provider)
  const feeManager = useFeeManagerContract(feeManagerAddress, provider)
  const ownCoin = useOwnContract(OWNTokenAddress, provider)
  const [yourBalance] = useAsync(() => provider.getBalance(account), [provider, account])
  const [factoryBalance] = useAsync(() => provider.getBalance(factoryAddress), [provider, factoryAddress])
  const [feeManagerBalanceEth] = useAsync(() => provider.getBalance(feeManagerAddress), [provider, feeManagerAddress])
  const [feeManagerBalanceOwn] = useAsync(
    (): Promise<BigNumber> => ownCoin.balanceOf(feeManagerAddress),
    [ownCoin, feeManagerAddress],
  )
  const [creationFee] = useAsync((): Promise<BigNumber> => feeManager.getCreationFee(), [provider, feeManager])
  const [paymentFee] = useAsync((): Promise<BigNumber> => feeManager.getPaymentFee(), [provider, feeManager])
  const [factoryOwner] = useAsync((): Promise<string> => factory.getOwner(), [provider, factory])
  const [feeManagerOwner] = useAsync((): Promise<string> => feeManager.getOwner(), [feeManager, factory])
  const [contracts] = useAsync(async () => {
    const results = await api.findContracts({})
    return results
      .filter(x => x.version === 7)
      .map(x => ({ address: x.address, title: x.type === 'AGREEMENT' ? x.title : x.factsheet.title }))
  }, [provider])

  async function collectFactoryFee () {
    await sendTransaction(feeManager, 'collectFee', [factory.address])
  }

  async function collectAgreementFee (address: string) {
    await sendTransaction(feeManager, 'collectFee', [address])
  }

  async function withdrawEth () {
    await sendTransaction(feeManager, 'withdraw', [account, ETH_TOKEN_ADDRESS])
  }

  async function withdrawOwn () {
    await sendTransaction(feeManager, 'withdraw', [account, OWNTokenAddress])
  }

  async function setCreationFee (value: BigNumberish) {
    await sendTransaction(feeManager, 'setCreationFee', [value])
  }

  async function setPaymentFee (value: BigNumberish) {
    await sendTransaction(feeManager, 'setPaymentFee', [value])
  }

  async function sendTransaction (contract: Contract, method: string, args: any[]) {
    const data = contract.interface.encodeFunctionData(method, args)
    await provider.send('eth_sendTransaction', [{
      to: feeManager.address,
      from: account,
      data,
    }])
  }

  return {
    yourBalance,
    factory,
    factoryBalance,
    factoryOwner,
    feeManager,
    feeManagerBalanceEth,
    feeManagerBalanceOwn,
    feeManagerOwner,
    creationFee,
    paymentFee,
    setPaymentFee,
    contracts,
    collectFactoryFee,
    withdrawEth,
    withdrawOwn,
    setCreationFee,
    collectAgreementFee,
  }
}

function useContract (address: string, abi: string[], provider: providers.Provider) {
  return useMemo(() => new Contract(address, abi, provider), [address, abi, provider])
}

function useFactoryContract (address: string, provider: providers.Provider) {
  return useContract(address, FactoryJSON.abi, provider)
}

function useFeeManagerContract (address: string, provider: providers.Provider) {
  return useContract(address, FeeManagerJSON.abi, provider)
}

function useOwnContract (address: string, provider: providers.Provider) {
  return useContract(address, OwnCoinJSON.abi, provider)
}

export function useAgreementContract (address: string, provider: providers.Provider) {
  return useContract(address, AgreementJSON.abi, provider)
}
