import React, { useState } from 'react'

import { ApiContract } from '@original-works/original-works-core/models'
import { LoadMoreButton } from '../../Components'
import { TransferHistoryListItem } from './TransferHistoryListItem'
import { useServices, useAsync } from '../../../../ui/hooks'

export interface TransferHistoryListProps {
  contract: ApiContract,
}

export function TransferHistoryList ({ contract }: TransferHistoryListProps) {
  const INITIAL_LIST_LENGTH = 5

  const [expanded, setExpanded] = useState(false)
  const { api } = useServices()
  const [transfers] = useAsync(() => api.getTransfers(contract.address), [contract.address])

  if (!transfers) {
    return null
  }

  return (
    <>
      <ol className="transfer-history-list">
        {transfers && transfers
          .slice(0, expanded ? transfers.length : INITIAL_LIST_LENGTH)
          .map((transfer, index) => (
            <TransferHistoryListItem
              key={index}
              transfer={transfer}
              totalSupply={contract.totalSupply}
            />
          ))
        }
      </ol>
      <LoadMoreButton
        hidden={expanded || transfers.length <= INITIAL_LIST_LENGTH}
        onClick={() => setExpanded(true)}
      />
    </>
  )
}
