"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asContractData = exports.asAssetContractData = exports.asAgreementContractData = void 0;
const sanitizers_1 = require("@restless/sanitizers");
const utils_1 = require("../utils");
const factsheet_1 = require("./factsheet");
exports.asAgreementContractData = (0, sanitizers_1.asObject)({
    type: (0, utils_1.asExactly)('AGREEMENT'),
    title: sanitizers_1.asString,
    description: sanitizers_1.asString,
});
exports.asAssetContractData = (0, sanitizers_1.asObject)({
    type: (0, utils_1.asExactly)('ASSET'),
    factsheet: factsheet_1.asFactsheet,
    agreementData: (0, sanitizers_1.asOptional)((0, sanitizers_1.asObject)({
        title: sanitizers_1.asString,
        description: sanitizers_1.asString,
    })),
    isLocked: (0, sanitizers_1.asOptional)(sanitizers_1.asBoolean),
});
exports.asContractData = (0, sanitizers_1.asAnyOf)([exports.asAgreementContractData, exports.asAssetContractData], 'contract data');
