import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { useAsync, useServices } from '../../../ui/hooks'
import { AgreementConfirmView } from './AgreementConfirmView'
import { SuccessView } from './SuccessView'
import { Values } from '../values'

interface AgreementModalProps {
  agreementData: Values,
  onClose (): void,
}

export function AgreementModal ({ agreementData, onClose }: AgreementModalProps) {
  const { api } = useServices()
  const history = useHistory()

  const [codeScanned, setCodeScanned] = useState(false)

  const [agreementId] = useAsync(() => api.saveAgreementData(agreementData), [])

  useEffect(() => {
    const interval = setInterval(update, 1000)

    async function update () {
      if (agreementId) {
        try {
          const { read, transactionSent } = await api.getAgreementProperties(agreementId)
          setCodeScanned(read)
          if (transactionSent) {
            clearInterval(interval)
          }
        } catch (e) {
          console.error(e)
        }
      }
    }

    return () => clearInterval(interval)
  }, [api, agreementId])

  if (!agreementId) {
    return null
  }
  if (!codeScanned) {
    return (
      <AgreementConfirmView
        agreementId={agreementId}
        agreementData={agreementData}
        onClose={onClose}
      />
    )
  }
  return <SuccessView onClose={history.goBack} />
}
