import React from 'react'
import cx from 'classnames'

import { useServices } from '../../../../ui/hooks'
import { ApiAssetContract } from '@original-works/original-works-core/models'
import { HeroFooter, HeroTop } from '../../Components'
import { PayoutActivity } from '@original-works/original-works-core/services'

export interface AssetHeroProps {
  contract: ApiAssetContract,
  payouts?: PayoutActivity[],
  isAllowedToView?: boolean,
}

export function AssetHero ({ isAllowedToView, contract, payouts }: AssetHeroProps) {
  const { accountService } = useServices()

  return (
    <div className="asset-hero">
      <div className={cx('asset-hero__left', !contract.factsheet.artwork && 'asset-hero__left--full')}>
        <HeroTop address={contract.address} title={contract.factsheet.title} note="Asset address" isAsset/>
        <h1 className="asset-hero__title">{contract.factsheet.title}</h1>
        <span className="asset-hero__subtitle">{contract.factsheet.subtitle}</span>
        <span className="asset-hero__note">{contract.factsheet.type}</span>
        <HeroFooter
          payouts={payouts}
          assetType={contract.factsheet.type}
          isAllowedToView={isAllowedToView}
          isAsset
          isSignedIn={accountService.isSignedIn()}
        />
      </div>
      <div className={cx('asset-hero__right', contract.factsheet.artwork && 'asset-hero__right--visible')}>
        <img src={contract.factsheet.artwork} alt="artwork" className="asset-hero__image"/>
      </div>
    </div>
  )
}
