import React from 'react'

import { EarningsHistoryBlock } from './EarningsHistoryBlock'
import { PayoutActivity } from '@original-works/original-works-core/services'
import { useAsync, useServices } from '../../../../ui/hooks'
import { NetworkName } from '@original-works/original-works-core/models'

export interface EarningsHistoryProps {
  address: string,
  networkName: NetworkName,
  isAsset?: boolean,
  payouts?: PayoutActivity[],
  isAllowedToView?: boolean,
  assetType?: string,
}

export function EarningsHistory ({
  assetType,
  networkName,
  isAllowedToView,
  address,
  isAsset,
  payouts,
}: EarningsHistoryProps) {
  const { api } = useServices()
  const [financials] = useAsync(() => api.getContractFinancials(address, networkName), [address])

  // TODO: Loading state

  return (
    <div className="earnings-history__wrapper">
      <h2 className="earnings-history__title">Earnings history</h2>
      <EarningsHistoryBlock
        assetType={assetType}
        isAllowedToView={isAllowedToView}
        isAsset={!!isAsset}
        payouts={payouts}
        financials={financials}
      />
    </div>
  )
}
