import React from 'react'
import cx from 'classnames'
import Big from 'big.js'

import { toFormattedPercentage, formatNumber } from '@original-works/original-works-core/utils'

interface TotalTokensProps {
  totalSupply: string,
  sum: string,
}

export function TotalTokens ({ totalSupply, sum }: TotalTokensProps) {
  const percentage = toFormattedPercentage(sum, totalSupply)
  const isOver = Big(sum).gt(totalSupply)
  const formattedSum = formatNumber(sum)
  const amount = isOver
    ? `+${formattedSum} over`
    : Big(sum).eq(totalSupply)
      ? `${formattedSum} Shares`
      : `${formatNumber(Big(totalSupply).minus(sum).toString())} remaining`

  return (
    <div className="total-tokens">
      <p className="total-tokens-text">Total:</p>
      <p className={cx('total-tokens-amount', isOver && 'error')}>{amount}</p>
      <p className={cx('total-tokens-percentage', isOver && 'error')}>{percentage}</p>
    </div>
  )
}
