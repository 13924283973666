import React from 'react'
import { EarningsDataItem, Period } from '@original-works/original-works-core/utils'

export interface BarModalProps {
  entry: EarningsDataItem,
  period: Period,
}

export function BarModal ({ entry, period }: BarModalProps) {
  return (
    <div className="eh-bar-modal">
      <div className="eh-bar-modal__header">
        <span className="eh-bar-modal__title">
          {entry.date.format(period === 'daily' ? 'dddd, DD MMMM YYYY' : 'MMMM YYYY')}
        </span>
      </div>
      <div className="eh-bar-modal__field">
        <span className="eh-bar-modal__label">Payments</span>
        <span className="eh-bar-modal__value">{entry.paymentCount}</span>
      </div>
      <div className="eh-bar-modal__field">
        <span className="eh-bar-modal__label">Total received</span>
        <span className="eh-bar-modal__value eh-bar-modal__value--blue">{entry.received.formatWithTicker()}</span>
      </div>
    </div>
  )
}
