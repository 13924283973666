"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asExactly = void 0;
const sanitizers_1 = require("@restless/sanitizers");
const asExactly = (expected) => (value, path) => {
    if (value === expected) {
        return sanitizers_1.Result.ok(expected);
    }
    else {
        return sanitizers_1.Result.error([{ path, expected: `exactly ${JSON.stringify(expected)}` }]);
    }
};
exports.asExactly = asExactly;
