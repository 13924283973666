import { Observable, timer, from, EMPTY } from 'rxjs'
import { switchMap, catchError } from 'rxjs/operators'

import {
  ApiAssetContract,
  ApiContract,
  ApiAgreementContract,
  ICacheService,
  ICacheEntry,
} from '@original-works/original-works-core/models'
import { noUndefined, shareCache } from '@original-works/original-works-core/utils'

import { Api } from './Api'
import { AccountService } from './AccountService'

export class ContractQueryService {
  contracts: Observable<ApiContract[]>
  private cache: ICacheEntry<ApiContract[] | undefined>

  constructor (
    private api: Api,
    private accountService: AccountService,
    cacheService: ICacheService,
    refreshInterval: number,
  ) {
    this.cache = cacheService.createEntry()

    this.contracts = timer(0, refreshInterval)
      .pipe(
        switchMap(() => from(this.fetchContracts()).pipe(
          catchError(() => EMPTY),
        )),
        noUndefined(), // don't save undefined to cache
        shareCache(this.cache),
        noUndefined(), // don't notify about the initial undefined from cache
      )
  }

  private async fetchContracts () {
    const assets = await this.api.findContracts({
      holder: this.accountService.getAddress(),
    })
    return assets.reverse()
  }

  searchAssets (searchString: string) {
    const items = this.cache.get()
    if (items === undefined) return undefined

    return items
      .filter(isAsset)
      .filter(x => x.factsheet.title.toLowerCase().includes(searchString.toLowerCase()))
  }

  searchAgreements (searchString: string) {
    const items = this.cache.get()
    if (items === undefined) return undefined

    return items
      .filter(isAgreement)
      .filter(x => x.title.toLowerCase().includes(searchString.toLowerCase()))
  }
}

function isAsset (contract: ApiContract): contract is ApiAssetContract {
  return contract.type === 'ASSET'
}

function isAgreement (contract: ApiContract): contract is ApiAgreementContract {
  return contract.type === 'AGREEMENT'
}
