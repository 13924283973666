import React from 'react'

import { FieldList } from '@original-works/original-works-core/models'

export interface FieldListProps {
  detailsSet: FieldList,
}

export function FieldListDisplay ({ detailsSet }: FieldListProps) {
  return (
    <div className="asset-info-details asset-info-details--field-list">
      {detailsSet.items.map(item => (
        <div className="asset-info-details__item" key={item.title}>
          <span className="asset-info-details__value">{item.value}</span>
          <span className="asset-info-details__title">{item.title}</span>
        </div>
      ))}
    </div>
  )
}
