"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.revelatorFactsheetToFactsheet = void 0;
const revelatorFactsheetToFactsheet = (revelatorFactsheet) => {
    const { asset } = revelatorFactsheet;
    return {
        type: asset.type,
        title: asset.title,
        subtitle: getSubtitle(asset.artists),
        artwork: asset.image && asset.image.url,
        enableRevenueCollection: false,
        platform: {
            name: 'Revelator',
            assetId: asset.id.toString(),
        },
        details: [
            {
                type: 'FIELD_LIST',
                name: 'Details',
                items: getFields(asset),
            },
            {
                type: 'GROUPED_LIST',
                name: 'Artists',
                items: getGroupedArtists(asset.artists),
            },
        ],
        metadata: revelatorFactsheet,
    };
};
exports.revelatorFactsheetToFactsheet = revelatorFactsheetToFactsheet;
const getSubtitle = (artists) => artists && artists.length && artists[0].name
    ? `by ${artists[0].name}`
    : '';
const getFields = (asset) => {
    const fields = [];
    asset.c_copyright && fields.push({
        title: '(C) Copyright',
        value: asset.c_copyright,
    });
    asset.p_copyright && fields.push({
        title: '(P) Copyright',
        value: asset.p_copyright,
    });
    asset.label && asset.label.name && fields.push({
        title: 'Label',
        value: asset.label.name,
    });
    if (asset.identifiers) {
        asset.identifiers.upc && fields.push({
            title: 'UPC',
            value: asset.identifiers.upc.toString(),
        });
        asset.identifiers.isrc && fields.push({
            title: 'ISRC',
            value: asset.identifiers.isrc,
        });
    }
    return fields;
};
const getGroupedArtists = (artists) => {
    return (artists && artists.map(artist => ({
        title: artist.name || '',
        info: artist.artist_role,
        group: 'Performed by',
    }))) || [];
};
