import React from 'react'

import { Details } from '@original-works/original-works-core/models'
import { DetailsDisplayLink } from './DetailsDisplayLink'

export interface AdditionalDetailsListProps {
  title: string,
  details: Details[],
}

export function AdditionalDetailsList ({ title, details }: AdditionalDetailsListProps) {
  return (
    <div className="asset-info-details-list">
      {details.length > 1 && details
        .slice(1, details.length)
        .map(detailsSet => (
          <DetailsDisplayLink title={title} key={detailsSet.name} detailsSet={detailsSet}/>
        ))}
    </div>

  )
}
