import React from 'react'

import { ApiAssetContract } from '@original-works/original-works-core/models'
import { AdditionalDetailsList } from './AdditionalDetailsList'
import { DetailsDisplay } from './DetailsDisplay'
import { PlatformBar } from './PlatformBar'

export interface AssetInfoProps {
  contract: ApiAssetContract,
}

export function AssetInfo ({ contract }: AssetInfoProps) {
  return (
    <div className="asset-info__wrapper">
      <h2 className="asset-info__title">Asset info</h2>
      <div className="asset-info">
        <div className="asset-info-top">
          <div className="asset-info-top__details">
            <span className="asset-info-top__title">{contract.factsheet.title}</span>
            <span className="asset-info-top__subtitle">{contract.factsheet.subtitle}</span>
            <span className="asset-info-top__note">{contract.factsheet.type}</span>
            <AdditionalDetailsList title={contract.factsheet.title} details={contract.factsheet.details} />
          </div>
          <PlatformBar platform={contract.factsheet.platform} />
        </div>
        <DetailsDisplay detailsSet={contract.factsheet.details[0]}/>
      </div>
    </div>
  )
}
