"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryCast = void 0;
const sanitizers_1 = require("@restless/sanitizers");
function tryCast(value, sanitizer) {
    const result = sanitizer(value, '');
    if (sanitizers_1.Result.isOk(result)) {
        return result.ok;
    }
    else {
        return undefined;
    }
}
exports.tryCast = tryCast;
