import React from 'react'
import dayjs from 'dayjs'

import { ApiActivityAdminAdded, ApiActivityAdminRemoved, ApiContract } from '@original-works/original-works-core/models'

import { ProfileImage } from '../../../../../ui/common'
import { useAsync, useModal, useServices } from '../../../../../ui/hooks'
import { BoldText, Date, NormalText } from './components'
import { AdminChangedModal } from '../ActivityModals/AdminChangedModal'

type AdminActivity = ApiActivityAdminAdded | ApiActivityAdminRemoved

export interface AdminChangedActivityListItemProps {
  activity: AdminActivity,
  contract: ApiContract,
}

export function AdminChangedActivityListItem ({ activity, contract }: AdminChangedActivityListItemProps) {
  const { userService, accountService } = useServices()
  const [user] = useAsync(async () => userService.getUser(activity.address), [activity])
  const [initiator] = useAsync(() => userService.getUser(activity.from), [activity])

  const detailsModal = useModal()

  return (
    <li className="contract-activity-list-item admin-changed-list-item" onClick={detailsModal.open}>
      <ProfileImage src={user?.imageURL} />
      <div className="contract-activity-list-item__text">
        <BoldText>
          {user?.name || '...'}
          {accountService.isSignedIn() && accountService.getAddress() === activity.address && ' (You)'}
        </BoldText>
        <NormalText>was</NormalText>
        <NormalText>
          {activity.type === 'ADMIN_ADDED'
            ? 'added'
            : 'removed'
          }
        </NormalText>
        <NormalText>as an admin by</NormalText>
        <BoldText>
          {initiator?.name || '...'}
          {accountService.isSignedIn() && accountService.getAddress() === activity.from && ' (You)'}
        </BoldText>
        <Date>
          {dayjs(activity.timestamp * 1000).format('DD MMM YYYY')}
        </Date>
      </div>
      <AdminChangedModal
        isVisible={detailsModal.isVisible}
        onClose={detailsModal.close}
        activity={activity}
        user={user}
        initiator={initiator}
        contract={contract}
      />
    </li>
  )
}
