import React from 'react'

import { DataBars } from './DataBars'
import { GridLines } from './GridLines'
import { EarningsChartData, Period } from '@original-works/original-works-core/utils'

export interface DataDisplayProps {
  period: Period,
  chartData: EarningsChartData,
  isAsset: boolean,
  maxChartValue: number,
}

export function DataDisplay ({ period, chartData, maxChartValue, isAsset }: DataDisplayProps) {
  return (
    <div style={{ position: 'relative' }}>
      <GridLines />
      <DataBars
        period={period}
        entries={period === 'monthly' ? chartData.monthly : chartData.daily}
        maxChartValue={maxChartValue}
        isAsset={isAsset}
      />
    </div>
  )
}
