import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { bem } from 'bem-components-react'

import { Checkmark } from '../../ui/common'
import { useAsync, useServices } from '../../ui/hooks'
import { PageNotFound } from '../../ui/PageNotFound'
import { PayToUserPage } from './PayToUserPage'

export function SuccessPaymentPage () {
  const location = useLocation()
  const history = useHistory()
  const { api, userService } = useServices()

  const sessionId = new URLSearchParams(location.search).get('session_id')

  const [payment, error] = useAsync(() => api.getPaymentData(sessionId), [])
  const [user] = useAsync(async () => {
    if (payment) {
      return userService.getUser(payment.to)
    }
  }, [payment])

  function navigateBack () {
    history.push(payment ? `/pay/${payment.userPaymentId}` : '/')
  }

  if (!sessionId || error) {
    return <PageNotFound />
  }

  return (
    <PayToUserPage onClose={navigateBack} className="pay-to-user_success_page">
      <div className="pay-to-user_modal-content-wrapper">
        <div className="pay-to-user_modal-main-info">
          <Checkmark />
          <p className="pay-to-user_modal-title">Your payment was sent!</p>
          <p className="pay-to-user_modal-note">We’ll notify the recipient when they receive the money.</p>
          {payment && user && (
            <div className="pay-to-user_payment-info">
              <Row>
                <p>Amount:</p>
                <p>${payment.amount} USD</p>
              </Row>
              <Row>
                <p>Paid to:</p>
                <p>{user.name}</p>
              </Row>
              <Row>
                <p>Transaction fee:</p>
                <p>$1 USD</p>
              </Row>
              <Row>
                <p>Payment method:</p>
                <p>{getCardInfo(payment.cardEndsWith, payment.cardType)}</p>
              </Row>
            </div>
          )}
        </div>
        <button onClick={navigateBack} className="pay-to-user_modal-button">Done</button>
      </div>
    </PayToUserPage>
  )
}

function getCardInfo (cardEndsWith: string, cardType?: string) {
  switch (cardType) {
    case 'apple_pay':
      return 'Apple Pay'
    case 'google_pay':
      return 'Google Pay'
    default:
      return `Card ending ${cardEndsWith}`
  }
}

const Row = bem.div('pay-to-user_payment-info-row')
