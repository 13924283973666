import React, { useState } from 'react'

import { ApiAgreement } from '@original-works/original-works-core/models'
import { CurrencyValue } from '@original-works/original-works-core/utils'
import { ExchangeRateService } from '@original-works/original-works-core/services'

import { useServices } from '../../../ui/hooks'
import { Confirm } from './Confirm'
import { PayForm } from './PayForm'

export interface PayRoyaltyModalProps {
  onClose: () => void,
  agreement: ApiAgreement,
  requiredPayment: CurrencyValue<'EUR'>,
  displayEUR?: boolean,
}

export function PayModal ({ onClose, agreement, requiredPayment, displayEUR }: PayRoyaltyModalProps) {
  const { exchangeRateService } = useServices()
  const [confirm, setConfirm] = useState(!requiredPayment.isZero())
  const [value, setValue] = useState(() => getInitialValue(requiredPayment, exchangeRateService))
  const [changed, setChanged] = useState(false)

  function onValueChange (value: string) {
    setValue(value)
    !changed && setChanged(true)
  }

  return !confirm ? (
    <PayForm
      onClose={onClose}
      agreement={agreement}
      onSubmit={() => setConfirm(true)}
      value={value}
      onChange={onValueChange}
    />
  ) : (
    <Confirm
      onClose={onClose}
      usdValue={new CurrencyValue(value || 0, 18, 'USD')}
      agreement={agreement}
      onBack={() => setConfirm(false)}
      eurValue={(!changed && !requiredPayment.isZero() && requiredPayment) || undefined}
      displayEUR={displayEUR}
    />
  )
}
function getInitialValue (requiredPayment: CurrencyValue<'EUR'>, exchangeRateService: ExchangeRateService) {
  return requiredPayment.isZero()
    ? ''
    : exchangeRateService.toUSD(requiredPayment).value.toFixed(2)
}
