import React from 'react'
import dayjs from 'dayjs'

import { PayoutActivity } from '@original-works/original-works-core/services'
import { CurrencyValue } from '@original-works/original-works-core/utils'
import { ApiContract } from '@original-works/original-works-core/models'

import { ProfileImage } from '../../../../../ui/common'
import { useAsync, useModal, useServices } from '../../../../../ui/hooks'

import { PaymentsModal } from '../ActivityModals/PaymentsModal'
import { BoldText, Date, GrayText, HighlightedText, NormalText } from './components'

export interface PaymentsListItemProps {
  contract: ApiContract,
  payout: PayoutActivity,
}

export function PaymentsListItem ({ contract, payout }: PaymentsListItemProps) {
  const { api, accountService } = useServices()
  const detailsModal = useModal()
  const [profile] = useAsync(() => api.getUserDetails(payout.from), [payout])

  return (
    <li className="contract-activity-list-item" onClick={detailsModal.open}>
      <ProfileImage src={profile?.imageURL} />
      <div className="contract-activity-list-item__text">
        <NormalText>Received</NormalText>
        <HighlightedText>
          {'$' + new CurrencyValue(payout.value.value, 18, 'USD').formatWithTicker()}
        </HighlightedText>
        <GrayText>({payout && payout.value.formatWithTicker()})</GrayText>
        <NormalText>from</NormalText>
        <BoldText>
          {profile ? profile.name : '...'}
          {accountService.isSignedIn() && accountService.getAddress() === payout.from && ' (You)'}
        </BoldText>
        <Date>
          {dayjs(payout.timestamp * 1000).format('DD MMM YYYY')}
        </Date>
      </div>
      <PaymentsModal
        contract={contract}
        isVisible={detailsModal.isVisible}
        onClose={detailsModal.close}
        profile={profile}
        payout={payout}
      />
    </li>
  )
}
