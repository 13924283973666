"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asRevelatorFactsheet = void 0;
const sanitizers_1 = require("@restless/sanitizers");
const asOrganization = (0, sanitizers_1.asObject)({
    id: (0, sanitizers_1.asOptional)(sanitizers_1.asNumber),
    uri: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
});
const asLabel = (0, sanitizers_1.asObject)({
    id: (0, sanitizers_1.asOptional)(sanitizers_1.asNumber),
    name: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    uri: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
});
const asSmartAssetConnection = (0, sanitizers_1.asObject)({
    status: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    uri: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    token_emission_ts: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
});
const asIdentifiers = (0, sanitizers_1.asObject)({
    upc: (0, sanitizers_1.asOptional)(sanitizers_1.asNumber),
    isrc: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
});
const asImage = (0, sanitizers_1.asObject)({
    url: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    height: (0, sanitizers_1.asOptional)(sanitizers_1.asNumber),
    width: (0, sanitizers_1.asOptional)(sanitizers_1.asNumber),
});
const asArtistImage = (0, sanitizers_1.asObject)({
    uri: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    height: (0, sanitizers_1.asOptional)(sanitizers_1.asNumber),
    width: (0, sanitizers_1.asOptional)(sanitizers_1.asNumber),
});
const asArtist = (0, sanitizers_1.asObject)({
    id: (0, sanitizers_1.asOptional)(sanitizers_1.asNumber),
    name: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    artist_role: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    is_primary: (0, sanitizers_1.asOptional)(sanitizers_1.asBoolean),
    uri: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    image: (0, sanitizers_1.asOptional)(asArtistImage),
});
const asRevelatorAsset = (0, sanitizers_1.asObject)({
    id: sanitizers_1.asNumber,
    type: sanitizers_1.asString,
    uri: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    title: sanitizers_1.asString,
    c_copyright: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    p_copyright: (0, sanitizers_1.asOptional)(sanitizers_1.asString),
    organization: (0, sanitizers_1.asOptional)(asOrganization),
    label: (0, sanitizers_1.asOptional)(asLabel),
    smart_asset_connection: (0, sanitizers_1.asOptional)(asSmartAssetConnection),
    identifiers: (0, sanitizers_1.asOptional)(asIdentifiers),
    image: (0, sanitizers_1.asOptional)(asImage),
    artists: (0, sanitizers_1.asOptional)((0, sanitizers_1.asArray)(asArtist)),
});
exports.asRevelatorFactsheet = (0, sanitizers_1.asObject)({
    asset: asRevelatorAsset,
});
