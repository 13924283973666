"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressUtilsService = void 0;
class AddressUtilsService {
    constructor(provider) {
        this.provider = provider;
    }
    async isContract(address) {
        const code = await this.provider.getCode(address);
        return code !== '' && code !== '0x';
    }
}
exports.AddressUtilsService = AddressUtilsService;
