import React from 'react'

import { ApiContract } from '@original-works/original-works-core/models'

import { ActivityList } from '../../../../ui/Contract/Sections/ContractActivity/ActivityList'
import { getFakePayouts } from '../../../../ui/Contract/utils'
import { useAsync, useServices } from '../../../../ui/hooks'
import { Spinner } from '../../../../ui/common'

export interface PaymentsReceivedProps {
  contract: ApiContract,
  isAsset?: boolean,
  isAllowedToView?: boolean,
  assetType?: string,
}

export function Activity ({ assetType, isAllowedToView, contract, isAsset }: PaymentsReceivedProps) {
  const { accountService, contractActivityService } = useServices()
  const isSignedIn = accountService.isSignedIn()
  const [activity, error] = useAsync(
    async () => (isSignedIn && isAllowedToView)
      ? contractActivityService.getActivityFor(contract.address)
      : (await getFakePayouts()).activity,
    [isSignedIn, isAllowedToView],
  )

  return (
    <div className="contract-activity__wrapper">
      <h2 className="contract-activity__title">Activity</h2>
      {!activity && !error
        ? <Spinner className="contract-activity__spinner" />
        : (
          <ActivityList
            contract={contract}
            activity={activity}
            assetType={assetType}
            isAllowedToView={isAllowedToView}
            isAsset={isAsset}
          />
        )
      }
    </div>
  )
}
