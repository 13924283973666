"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asCurrencyValue = void 0;
const sanitizers_1 = require("@restless/sanitizers");
const utils_1 = require("../utils");
const tickers = ['USD', 'EUR', 'OWN', 'ETH', 'USDC'];
function asCurrencyValue(expectedTicker) {
    const expected = `${expectedTicker ? `${expectedTicker} ` : ''}currency value`;
    return (data, path) => {
        if (typeof data === 'object' && data !== null) {
            const { value, ticker, decimals } = data;
            if ((typeof value === 'string' || typeof value === 'number') &&
                typeof ticker === 'string' &&
                tickers.includes(ticker) &&
                (expectedTicker === undefined || ticker === expectedTicker) &&
                typeof decimals === 'number' &&
                decimals >= 0) {
                try {
                    return sanitizers_1.Result.ok(new utils_1.CurrencyValue(value, decimals, ticker));
                }
                catch (_a) {
                    return sanitizers_1.Result.error([{ path, expected }]);
                }
            }
        }
        return sanitizers_1.Result.error([{ path, expected }]);
    };
}
exports.asCurrencyValue = asCurrencyValue;
