"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noUndefined = exports.toSubscribeMethod = exports.shareCache = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const CachingSubject_1 = require("./CachingSubject");
const shareCache = (entry) => (0, rxjs_1.pipe)((0, operators_1.multicast)(() => new CachingSubject_1.CachingSubject(entry)), (0, operators_1.refCount)());
exports.shareCache = shareCache;
function toSubscribeMethod(observable) {
    return (onNext, onError) => {
        const subscription = observable.subscribe(onNext, onError);
        return () => subscription.unsubscribe();
    };
}
exports.toSubscribeMethod = toSubscribeMethod;
const noUndefined = () => (0, operators_1.filter)(x => x !== undefined);
exports.noUndefined = noUndefined;
