import React, { ReactNode } from 'react'

import { Modal } from '../../../../../ui/common'

export interface ActivityModalProps {
  isVisible: boolean,
  onClose: () => void,
  title: string,
  children: ReactNode,
}

export function ActivityModal (props: ActivityModalProps) {
  if (!props.isVisible) {
    return null
  }

  return (
    <Modal onClose={props.onClose} className="activity-modal">
      <p className="activity-modal__title">{props.title}</p>
      {props.children}
    </Modal>
  )
}
