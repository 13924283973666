import React from 'react'

import { Footer, NavBar } from '../../ui/common'
import { useRedirectHome } from '../../ui/hooks'

export function EmailConfirmed () {
  const goHome = useRedirectHome()

  return (
    <div className="page">
      <NavBar className="beige" />
      <div className="email-confirmed">
        <p className="email-confirmed__main-text">Nice!</p>
        <p className="email-confirmed__main-text--highlight">Your email has been verified.</p>
        <p className="email-confirmed__note">
          We’ll try to automatically redirect you in a few seconds, or you can hit the button below to return home.
        </p>
        <button className="button email-confirmed__home-button" onClick={goHome}>Go home</button>
      </div>
      <Footer />
    </div>
  )
}
