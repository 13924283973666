import React from 'react'
import { bem } from 'bem-components-react'

import { Holder } from '@original-works/original-works-core/models'
import { calculatePercentage } from '@original-works/original-works-core/utils'

import { UserProfile } from '../../../../ui/common'
import { shorten } from '../../../../ui/utils'

interface HoldersListProps {
  holders: Holder[],
  tokens: string,
  onAdminChange (index: number): void,
  onTokensChange (index: number, value: string): void,
  onPercentageChange (index: number, value: string): void,
  onRemoveHolder (address: string): void,
}

export function HoldersList (props: HoldersListProps) {
  return (
    <div className="holders-list">
      <Header />
      <Holders {...props} />
    </div>
  )
}

function Header () {
  return (
    <div className="holders-list__header">
      <ColumnHeader>Name</ColumnHeader>
      <ColumnHeader>Wallet Address</ColumnHeader>
      <ColumnHeader right>Shares</ColumnHeader>
      <ColumnHeader right>% of total</ColumnHeader>
    </div>
  )
}

function Holders (props: HoldersListProps) {
  return (
    <>
      {props.holders.map((holder, index) =>
        <div className="holders-list__item" key={index}>
          <div className="holder">
            <UserProfile userAddress={holder.address} />
            {holder.isAdmin && <p className="holder-admin-status">Admin</p>}
          </div>
          <Address spread={index === 0}>{shorten(holder.address)}</Address>
          {index !== 0 && (
            <button
              type="button"
              onClick={() => props.onAdminChange(index)}
              className="make-admin-btn"
            >
              {`${holder.isAdmin ? 'Remove' : 'Make'} Admin`}
            </button>
          )}
          <input
            className="holders-list__item-shares"
            value={holder.balance}
            onChange={e => props.onTokensChange(index, e.target.value)}
          />
          <div className="holders-list__item-shares-percentage__wrapper">
            <input
              className="holders-list__item-shares-percentage"
              value={`${calculatePercentage(holder.balance, props.tokens)}`}
              onChange={(e) => props.onPercentageChange(index, e.target.value)}
            />
            <p className="holders-list__item-shares__percentage-sign">%</p>
          </div>
          {index !== 0 && (
            <div className="holders-list__remove-item--wrapper">
              <button
                className="holders-list__remove-item"
                onClick={() => props.onRemoveHolder(holder.address)}
              >
                -
              </button>
            </div>
          )}
        </div>,
      )}
    </>
  )
}

const ColumnHeader = bem.p('holders-list__column-header', ['right'])
const Address = bem.p('holders-list__item-address', ['spread'])
