"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickMaxChartValue = void 0;
const CurrencyValue_1 = require("../CurrencyValue");
const USD = (value) => new CurrencyValue_1.CurrencyValue(value, 18, 'USD');
const floor = (big) => big.round(0, 0);
const ceil = (big) => big.round(0, 3);
const toNumber = (big) => parseFloat(big.toString());
function pickMaxChartValue(dataItems) {
    let highestValue = getHighestValue(dataItems);
    if (highestValue.isZero()) {
        return 200;
    }
    let currentPowerOf10 = 0;
    while (highestValue.gt(USD(1))) {
        highestValue = highestValue.div(10);
        currentPowerOf10++;
    }
    if (currentPowerOf10 >= 2 && highestValue.lt(USD(0.5))) {
        currentPowerOf10--;
        return toNumber(ceil(highestValue.value)
            .mul(floor(highestValue.value.mul(10).add(1)))
            .mul(10 ** currentPowerOf10));
    }
    return toNumber(ceil(highestValue.value)
        .mul(10 ** currentPowerOf10));
}
exports.pickMaxChartValue = pickMaxChartValue;
function getHighestValue(dataItems) {
    let highestValue = USD('0');
    dataItems.forEach(dataItem => {
        var _a;
        if ((_a = dataItem.earned) === null || _a === void 0 ? void 0 : _a.gte(highestValue)) {
            highestValue = dataItem.earned;
        }
        if (dataItem.received.gte(highestValue)) {
            highestValue = dataItem.received;
        }
    });
    return highestValue;
}
