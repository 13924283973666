import React from 'react'

import { useAsync, useServices } from '../../../ui/hooks'
import { AgreementListItem } from './AgreementListItem'

export const AgreementList = () => {
  const { teostoPayoutService } = useServices()
  const [agreements] = useAsync(() => teostoPayoutService.getAgreements(), [])

  return (
    <section className="agreements__left">
      <h1 className="page__title">Agreements</h1>
      <header className="agreement-list__header teosto">
        <span className="agreement-list__header-item teosto">Agreement</span>
        <span className="agreement-list__header-item teosto">Total paid</span>
        <span className="agreement-list__header-item teosto">Payment required</span>
      </header>
      <ol className="agreement-list teosto">
        {agreements && agreements.map(agreement => (
          <AgreementListItem
            key={agreement.address}
            agreement={agreement}
          />
        ))}
      </ol>
    </section>
  )
}
