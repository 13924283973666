import React, { useState } from 'react'

import { toDataURL } from 'qrcode'
import { Modal } from '../../../ui/common'
import { useAsync } from '../../../ui/hooks'
import copy from 'copy-to-clipboard'

export interface QRCodeModalProps {
  address: string,
  title: string,
  visible: boolean,
  onClose: () => void,
  isAsset?: boolean,
}

export function QRCodeModal ({ address, title, visible, onClose, isAsset }: QRCodeModalProps) {
  const [dataUrl] = useAsync(() => toDataURL(address), [address])
  const [copied, setCopied] = useState(false)

  const onClick = () => {
    if (!copied) {
      copy(address)
      setCopied(true)
      setTimeout(() => setCopied(false), 3000)
    }
  }

  if (!visible) {
    return null
  }

  return (
    <Modal
      onClose={onClose}
      className="qr-code-modal"
      small
    >
      <div className="qr-code-modal__qr-code-field">
        <img src={dataUrl} alt="QRCode" className="qr-code-modal__qr-code-image"/>
      </div>
      <div className="qr-code-modal__text">
        <span className="qr-code-modal__title">{title}</span>
        <span className="qr-code-modal__address" onClick={() => onClick()}>{copied ? 'Copied!' : address}</span>
        <span className="qr-code-modal__instruction">
          <strong>Scan this QR Code or copy this address </strong>
          {`to send payments directly to this ${isAsset ? 'asset' : 'agreement'}.`}
        </span>
      </div>
    </Modal>
  )
}
