import React from 'react'

import { Platform } from '@original-works/original-works-core/models'

export interface PlatformBarProps {
  platform: Platform,
}

export function PlatformBar ({ platform }: PlatformBarProps) {
  return (
    <div className="asset-info-platform">
      {platform.imageURL && (<img src={platform.imageURL} alt="PlatformIcon" className="asset-info-platform__icon"/>)}
      <div className="asset-info-platform__text">
        <span className="asset-info-platform__id">{platform.assetId}</span>
        <span className="asset-info-platform__name">{platform.name} ID</span>
      </div>
    </div>
  )
}
