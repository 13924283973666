"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.asFactsheet = void 0;
const sanitizers_1 = require("@restless/sanitizers");
const asFactsheet_1 = require("./oldRevelatorFactsheet/asFactsheet");
const revelatorFactsheetToFactsheet_1 = require("./oldRevelatorFactsheet/revelatorFactsheetToFactsheet");
const asFactsheet_2 = require("./asFactsheet");
__exportStar(require("./factsheet"), exports);
const asFactsheetFromRevelator = (0, sanitizers_1.asMapped)(asFactsheet_1.asRevelatorFactsheet, revelatorFactsheetToFactsheet_1.revelatorFactsheetToFactsheet);
const asFactsheet = (value, path) => {
    return isRevelatorFactsheet(value)
        ? asFactsheetFromRevelator(value, path)
        : (0, asFactsheet_2.asFactsheet)(value, path);
};
exports.asFactsheet = asFactsheet;
function isRevelatorFactsheet(factsheet) {
    try {
        return factsheet.asset.uri.startsWith('revv1');
    }
    catch (_a) {
        return false;
    }
}
